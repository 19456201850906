//#region IMPORT

import { GeneralModel } from "./bases/general.model";

//#endregion

//#region CLASS

export class UserSessionModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for vehicle user session model, used for user session.
		Author: Andri Septiawan.
		Created on : Monday, 18 January 2021.        		Updated on : Monday, 18 January 2021.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

	UserToken?: string;

	/* Attribute - END */
}

//#endregion