//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AboutComponent } from "./components/about/about.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HomeComponent } from "./components/home/home.component";
import { ReportComponent } from "./components/report/report.component";
import { SigninComponent } from "./components/signin/signin.component";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { PublicGuardService } from "./services/guards/public.guard.service";

//#endregion

//#region DECLARATION

const arrayRoute: Routes=
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "home",
		redirectTo: "home/dashboard",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "dashboard",
				component: DashboardComponent,
			},
			{
				path: "submission",
				loadChildren: () => import("./components/submission/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "report",
				component: ReportComponent,
			},
			{
				path: "about",
				component: AboutComponent,
			}
		]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(arrayRoute)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
