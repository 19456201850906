//#region IMPORT

import { Color } from "@swimlane/ngx-charts";
import { DashboardBaseModel } from "./bases/dashboard.base.model";
import { ChartItemModel } from "./chartitem.model";

//#endregion

//#region CLASS

export class DashboardProductHajjAndUmrahSummaryModel extends DashboardBaseModel
{
	//#region UMRAH

	listModelChartItemStatusTypeProductUmrah?: Array<ChartItemModel> = [];
	modelColorStatusTypeProductUmrah: Color = this.setColorDefault();

	listModelChartItemStatusOnProgressProductUmrah?: Array<ChartItemModel> = [];
	modelColorStatusOnProgressProductUmrah: Color = this.setColorDefault();

	listModelChartItemStatusApprovedProductUmrah?: Array<ChartItemModel> = [];
	modelColorStatusApprovedProductUmrah: Color = this.setColorDefault();

	listModelChartItemStatusRejectedProductUmrah?: Array<ChartItemModel> = [];
	modelColorStatusRejectedProductUmrah: Color = this.setColorDefault();

	listModelChartItemPremiumAmountProductUmrah?: Array<ChartItemModel> = [];
	modelColorStatusPremiumAmountProductUmrah: Color = this.setColorDefault();

	listModelChartItemPolicyProductUmrah?: Array<ChartItemModel> = [];
	modelColorStatusPolicyProductUmrah: Color = this.setColorDefault();

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region CLEAR

	clearChartResult(): void
	{
		this.listModelChartItemStatusTypeProductUmrah = [];
		this.modelColorStatusTypeProductUmrah = this.setColorDefault();

		this.listModelChartItemStatusOnProgressProductUmrah = [];
		this.modelColorStatusOnProgressProductUmrah = this.setColorDefault();

		this.listModelChartItemStatusApprovedProductUmrah = [];
		this.modelColorStatusApprovedProductUmrah = this.setColorDefault();

		this.listModelChartItemStatusRejectedProductUmrah = [];
		this.modelColorStatusRejectedProductUmrah = this.setColorDefault();

		this.listModelChartItemPremiumAmountProductUmrah = [];
		this.modelColorStatusPremiumAmountProductUmrah = this.setColorDefault();

		this.listModelChartItemPolicyProductUmrah = [];
		this.modelColorStatusPolicyProductUmrah = this.setColorDefault();
	}

	clearChartForRequest(): void
	{
		this.listModelChartItemStatusTypeProductUmrah = undefined;
		this.modelColorStatusTypeProductUmrah = this.setColorDefault();

		this.listModelChartItemStatusOnProgressProductUmrah = undefined;
		this.modelColorStatusOnProgressProductUmrah = this.setColorDefault();

		this.listModelChartItemStatusApprovedProductUmrah = undefined;
		this.modelColorStatusApprovedProductUmrah = this.setColorDefault();

		this.listModelChartItemStatusRejectedProductUmrah = undefined;
		this.modelColorStatusRejectedProductUmrah = this.setColorDefault();

		this.listModelChartItemPremiumAmountProductUmrah = undefined;
		this.modelColorStatusPremiumAmountProductUmrah = this.setColorDefault();

		this.listModelChartItemPolicyProductUmrah = undefined;
		this.modelColorStatusPolicyProductUmrah = this.setColorDefault();
	}

	//#endregion
}

//#endregion