//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductUmrahAttachmentModel } from "../models/productumrahattachment.model";
import { ProductUmrahSummaryModel } from "../models/productumrahsummary.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductumrahService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectProductUmrahByAttributesForTravel(interfaceGeneralService: GeneralServiceInterface,	modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYATTRIBUTESFORTRAVEL);
	}

	selectProductUmrahBatchByAttributesForTravel(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBATCHBYATTRIBUTESFORTRAVEL);
	}

	selectProductByAttributesReportForTravel(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYATTRIBUTESREPORTFORTRAVEL);
	}

	selectProductForExportTravel(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUT_SELECTPRODUCTFOREXPORTTRAVEL);
	}

	selectProductUmrahByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYTOKEN);
	}

	selectProductUmrahByBatchNumber(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYBATCHNUMBER);
	}

	//#endregion


	//#region INSERT

	insertListProductUmrah(interfaceGeneralService: GeneralServiceInterface,	 arrayProductGeneralModel: Array<ProductGeneralModel>): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(arrayProductGeneralModel), WebAddressConstant.STRING_URL_PRODCUTUMRAH_INSERTLISTPRODUCT);
	}

	insertProductUmrah(interfaceGeneralService: GeneralServiceInterface,	 modelProductGeneral: ProductGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTUMRAH_INSERTPRODUCT);
	}

	//#endregion


	//#region VALIDATE

	validateListProductUmrah(interfaceGeneralService: GeneralServiceInterface, modelProductUmrahSummaryModel: ProductUmrahSummaryModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductUmrahSummaryModel), WebAddressConstant.STRING_URL_PRODCUTUMRAH_VALIDATELISTPRODUCT);
	}

	//#endregion


	//#region DOWNLOAD & UPLOAD

	uploadProductAttachment(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTUMRAH_UPLOADPRODUCTATTACHMENT);
	}

	downloadProductUmrahByDocumentToken(interfaceGeneralService: GeneralServiceInterface, modelProductUmrahAttachment: ProductUmrahAttachmentModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductUmrahAttachment), WebAddressConstant.STRING_URL_PRODCUTUMRAH_DOWNLOADPRODUCTATTACHMENTBYTOKEN);
	}

	downloadContributionNoteByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTUMRAH_DOWNLOADCONTRIBUTIONNOTEBYTOKEN);
	}

	downloadComissionNoteByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTUMRAH_DOWNLOADCOMISSIONNOTEBYTOKEN);
	}

	downloadCoverageOverviewByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTUMRAH_DOWNLOADCOVERAGEOVERVIEWBYTOKEN);
	}

	//#endregion
}

//#endregion
