<div class="DivTableControl">
	<div class="DivTableSubControl">
		<div [ngClass]="{ 'Enable' : !_booleanButtonFirst, 'Disable' : _booleanButtonFirst }" class="DivButtonFirst"><input type="button" (click)="goToFirst();" [disabled]="_booleanButtonFirst"></div>
		<div [ngClass]="{ 'Enable' : !_booleanButtonPrevious, 'Disable' : _booleanButtonPrevious }" class="DivButtonPrevious"><input type="button" (click)="goToPrevious();" [disabled]="_booleanButtonPrevious"></div>
		<span *ngIf="_modelTableForm.PageTotal !== null && _modelTableForm.PageTotal !== undefined && _modelTableForm.PageTotal > 0" class="SpanTablePage">Halaman {{ _modelTableForm.Page }} dari {{ _modelTableForm.PageTotal }}</span>
		<span *ngIf="_modelTableForm.PageTotal === null || _modelTableForm.PageTotal === undefined || _modelTableForm.PageTotal === 0" class="SpanTablePage">Tidak ada halaman</span>
		<div [ngClass]="{ 'Enable' : !_booleanButtonNext, 'Disable' : _booleanButtonNext }" class="DivButtonNext"><input type="button" (click)="goToNext();" [disabled]="_booleanButtonNext"></div>
		<div [ngClass]="{ 'Enable' : !_booleanButtonLast, 'Disable' : _booleanButtonLast }" class="DivButtonLast"><input type="button" (click)="goToLast();" [disabled]="_booleanButtonLast"></div>
	</div>

	<div class="DivTableSubControl">
		<label for="selectRowPerPage">Baris per halaman</label>
		<select id="selectRowPerPage" [(ngModel)]="_modelTableForm.RowPerPage" (change)="listenRowPerPage();">
			<option [ngValue]="20">20</option>
			<option [ngValue]="40">40</option>
		</select>
	</div>
</div>