//#region IMPORT

import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, Injectable } from "@angular/core";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from "@angular/material/core";
import { Router } from "@angular/router";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { DashboardBaseModel } from "src/app/models/bases/dashboard.base.model";
import { DashboardProductHajjAndUmrahSummaryModel } from "src/app/models/dashboardproducthajjandumrahsummary.model";
import { ResponseModel } from "src/app/models/response.model";
import { DashboardService } from "src/app/services/dashboard.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeChartComponent } from "../bases/baseauthourizechart.component";

//#endregion


//#region CONST

export const appDateFormats: MatDateFormats =
{
	parse:
	{
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display:
	{
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric"
		},
		monthYearA11yLabel: { year: "numeric", month: "long" },
	}
};

//#endregion


//#region CLASS DECLARATION

@Injectable() export class AppDateAdapter extends NativeDateAdapter
{
	// eslint-disable-next-line @typescript-eslint/ban-types
	override format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			let stringDay: string = date.getUTCDate().toString();
			stringDay = +stringDay < 10 ? "0" + stringDay : stringDay;
			let stringMonth: string = (date.getUTCMonth() + 1).toString();
			stringMonth = +stringMonth < 10 ? "0" + stringMonth : stringMonth;
			const numberYear = date.getFullYear();
			return `${stringDay}/${stringMonth}/${numberYear}`;
		}
		return date.toDateString();
	}
}

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-dashboard",
		templateUrl: "./dashboard.component.html",
		styleUrls: ["./dashboard.component.sass"],
		providers:
		[
			{provide: DateAdapter, useClass: AppDateAdapter},
			{provide: MAT_DATE_FORMATS, useValue: appDateFormats}
		]
	}
)

//#endregion


//#region CLASS

export class DashboardComponent extends BaseAuthourizeChartComponent
{
	//#region DECLARATION

	public _modelDashboardProductHajjAndUmrahSummary: DashboardProductHajjAndUmrahSummaryModel;
	public _modelDashboardBase: DashboardBaseModel;

	//#region CONSTRUCTOR

	constructor(private _serviceDashboard: DashboardService, serviceSession: SessionService, router: Router, breakpointObserver: BreakpointObserver)
	{
		super(serviceSession, router, breakpointObserver);
		this._modelDashboardProductHajjAndUmrahSummary = new DashboardProductHajjAndUmrahSummaryModel();
		this._modelDashboardBase = new DashboardBaseModel();

		this.setStartDate(this._modelDashboardBase.StartDate);
		this.setEndDate(this._modelDashboardBase.EndDate);
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
		this.selectDashboardProductMicroInsuranceSyariah(this, () => {});
	}

	//#endregion


	//#region WEB SERVICE

	private selectDashboardProductMicroInsuranceSyariah(componentCurrent: DashboardComponent, success: () => void): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		componentCurrent._modelDashboardProductHajjAndUmrahSummary.clearChartResult();

		const modelResponseValidate: ResponseModel = this._modelDashboardProductHajjAndUmrahSummary.validatePeriodASMIK();

		if(modelResponseValidate.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceDashboard.selectDashboardProductHajjAndUmrahForTravel
			({
				success(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();

					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelDashboardProductHajjAndUmrahSummary = new DashboardProductHajjAndUmrahSummaryModel();
							componentCurrent._modelDashboardProductHajjAndUmrahSummary.setModelFromString(modelResponse.Data);

							//#region UMRAH

							componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusTypeProductUmrah = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusTypeProductUmrah);
							componentCurrent._modelDashboardProductHajjAndUmrahSummary.modelColorStatusTypeProductUmrah.domain = componentCurrent.generateChartColorTypeStatus(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusTypeProductUmrah);

							componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusOnProgressProductUmrah = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusOnProgressProductUmrah);
							componentCurrent._modelDashboardProductHajjAndUmrahSummary.modelColorStatusOnProgressProductUmrah.domain = componentCurrent.generateChartColorWaitingForApproval(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusOnProgressProductUmrah);

							componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusApprovedProductUmrah = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusApprovedProductUmrah);
							componentCurrent._modelDashboardProductHajjAndUmrahSummary.modelColorStatusApprovedProductUmrah.domain = componentCurrent.generateChartColorApproved(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusApprovedProductUmrah);

							componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusRejectedProductUmrah = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusRejectedProductUmrah);
							componentCurrent._modelDashboardProductHajjAndUmrahSummary.modelColorStatusRejectedProductUmrah.domain = componentCurrent.generateChartColorRejected(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusRejectedProductUmrah);

							componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemPremiumAmountProductUmrah = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemPremiumAmountProductUmrah);
							componentCurrent._modelDashboardProductHajjAndUmrahSummary.modelColorStatusPremiumAmountProductUmrah.domain = componentCurrent.generateChartColor(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemPremiumAmountProductUmrah);

							componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemPolicyProductUmrah = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemPolicyProductUmrah);
							componentCurrent._modelDashboardProductHajjAndUmrahSummary.modelColorStatusPolicyProductUmrah.domain = componentCurrent.generateChartColor(componentCurrent._modelDashboardProductHajjAndUmrahSummary.listModelChartItemPolicyProductUmrah);

							//#endregion

							componentCurrent._functionUserInterface.updateLoadingProgress();

							success();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.selectDashboardProductMicroInsuranceSyariah(componentCurrent, () => { success(); }); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelDashboardProductHajjAndUmrahSummary);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidate, () => { });
		}

	}

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region SETTER

	private setStartDate(dateStart?: Date): void
	{
		if (dateStart == null || dateStart === undefined)
		{
			this._modelDashboardProductHajjAndUmrahSummary.StartDate = undefined;
		}
		else
		{
			this._modelDashboardProductHajjAndUmrahSummary.StartDate = dateStart;
		}
	}

	private setEndDate(dateEnd?: Date): void
	{
		if (dateEnd == null || dateEnd === undefined)
		{
			this._modelDashboardProductHajjAndUmrahSummary.EndDate = undefined;
		}
		else
		{
			this._modelDashboardProductHajjAndUmrahSummary.EndDate = dateEnd;
		}
	}

	//#endregion


	//#region GETTER

	getEndDate(event: any): void
	{
		this._modelDashboardBase.EndDate = event.value.clearUTC();

		this.setEndDate(this._modelDashboardBase.EndDate);

		if
		(
			this._modelDashboardProductHajjAndUmrahSummary.StartDate == null ||
			this._modelDashboardProductHajjAndUmrahSummary.StartDate === undefined ||
			this._modelDashboardProductHajjAndUmrahSummary.EndDate == null ||
			this._modelDashboardProductHajjAndUmrahSummary.EndDate === undefined
		)
		{

		}
		else
		{
			this.selectDashboardProductMicroInsuranceSyariah(this, () => {});
		}
	};

	getStartDate(event: any): void
	{
		this._modelDashboardBase.StartDate = event.value.clearUTC();
		this.setStartDate(this._modelDashboardBase.StartDate);
	};

	//#endregion
}

//#endregion