//#region CLASS

export class NumberConstant
{
	//#region USER INTERFACE

	static NUMBER_PRODUCTASRI_GALLERY_SIZE: number = 5;
	static NUMBER_PRODUCTOTO_GALLERY_SIZE: number = 9;

	static NUMBER_VALUE_THEME_SECONDARY_DARK_RED: number = 193;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_GREEN: number = 89;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_BLUE: number = 29;

	static NUMBER_VALUE_THEME_PRIMARY_DARK_RED: number = 40;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_GREEN: number = 118;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_BLUE: number = 188;

	static NUMBER_VALUE_THEME_CHART_STEP_RED: number = 20;
	static NUMBER_VALUE_THEME_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_CHART_STEP_BLUE: number = 3;

	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_RED: number = 5;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_BLUE: number = 15;

	static NUMBER_VALUE_THEME_CHART_APPROVED_RED: number = 52;
	static NUMBER_VALUE_THEME_CHART_APPROVED_GREEN: number = 155;
	static NUMBER_VALUE_THEME_CHART_APPROVED_BLUE: number = 112;

	static NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_RED: number = 0;
	static NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_GREEN: number = 93;
	static NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_BLUE: number = 163;

	static NUMBER_VALUE_THEME_CHART_REJECTED_RED: number = 242;
	static NUMBER_VALUE_THEME_CHART_REJECTED_GREEN: number = 111;
	static NUMBER_VALUE_THEME_CHART_REJECTED_BLUE: number = 33;


	static NUMBER_VALUE_DECIMAL_PREVIEW: number = 2;

	static NUMBER_EXCEL_COLUMN_INSUREDNAME: number = 0;
	static NUMBER_EXCEL_COLUMN_BIRTHPLACE: number = 1;
	static NUMBER_EXCEL_COLUMN_BIRTHDATE: number = 2;
	static NUMBER_EXCEL_COLUMN_GENDER: number = 3;
	static NUMBER_EXCEL_COLUMN_OCCUPATION: number = 4;
	static NUMBER_EXCEL_COLUMN_MARTIALSTATUS: number = 5;
	static NUMBER_EXCEL_COLUMN_PASSPORT: number = 6;
	static NUMBER_EXCEL_COLUMN_FLIGHTNUMBER: number = 7;
	static NUMBER_EXCEL_COLUMN_PERIODTYPE: number = 8;
	static NUMBER_EXCEL_COLUMN_DEPARTUREDATE: number = 9;
	static NUMBER_EXCEL_COLUMN_ARRIVALDATE: number = 10;
	static NUMBER_EXCEL_COLUMN_COVERAGETYPE: number = 11;

	static NUMBER_VALIDATE_INSUREDPASSPORTNUMBERMIN: number = 6;
	static NUMBER_VALIDATE_INSUREDPASSPORTNUMBERMAX: number = 12;
	static NUMBER_VALIDATE_INSUREDFLIGHTNUMBERMIN: number = 3;
	static NUMBER_VALIDATE_INSUREDFLIGHTNUMBERMAX: number = 10;


	//#endregion
}

//#endregion