//#region CLASS

export class ResponseCodeConstant
{
	/*
		SERVICE RESPONSE CODE - START
		Description : List of service response code, inside you will have module which contains [0-9][0-9] and case [0-9][0-9][0-9][0-9][0-9][0-9] which the first two digit is same wth the referred module. This list need to be inline with the language pack JSON.
		Author: Ibrahim Aziz.
		Created on : Friday, 1 February 2019.			Updated on : Friday, 28 February 2020.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	//#region GENERAL

	static STRING_RESPONSECODE_SERVICE_SUCCESS: string = "0";

	static STRING_RESPONSECODE_MODULE_GENERAL: string = "00";
	static STRING_RESPONSECODE_CASE_GENERAL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0000";
	static STRING_RESPONSECODE_CASE_GENERAL_FORMINVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0001";
	static STRING_RESPONSECODE_CASE_GENERAL_FORMVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0002";
	static STRING_RESPONSECODE_CASE_GENERAL_NOTFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0003";
	static STRING_RESPONSECODE_CASE_GENERAL_FOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0004";
	static STRING_RESPONSECODE_CASE_GENERAL_INSERTFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0005";
	static STRING_RESPONSECODE_CASE_GENERAL_INSERTSUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0006";
	static STRING_RESPONSECODE_CASE_GENERAL_UPDATEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0007";
	static STRING_RESPONSECODE_CASE_GENERAL_UPDATESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0008";
	static STRING_RESPONSECODE_CASE_GENERAL_DELETEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0009";
	static STRING_RESPONSECODE_CASE_GENERAL_DELETESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0010";
	static STRING_RESPONSECODE_CASE_GENERAL_JSONSERIALIZEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0011";
	static STRING_RESPONSECODE_CASE_GENERAL_JSONSERIALIZESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0012";
	static STRING_RESPONSECODE_CASE_GENERAL_JSONDESERIALIZEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0013";
	static STRING_RESPONSECODE_CASE_GENERAL_JSONDESERIALIZESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0014";
	static STRING_RESPONSECODE_CASE_GENERAL_CULTUREFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0015";
	static STRING_RESPONSECODE_CASE_GENERAL_CULTURESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0016";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYNOTFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0017";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0018";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYINSERTFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0019";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYINSERTSUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0020";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYUPDATEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0021";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYUPDATESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0022";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYDELETEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0023";
	static STRING_RESPONSECODE_CASE_GENERAL_HITHISTORYDELETESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0024";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYNOTFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0025";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0026";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYINSERTFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0027";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYINSERTSUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0028";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYUPDATEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0029";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYUPDATESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0030";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYDELETEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0031";
	static STRING_RESPONSECODE_CASE_GENERAL_REQUESTHISTORYDELETESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0032";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYNOTFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0033";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0034";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYINSERTFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0035";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYINSERTSUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0036";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYUPDATEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0037";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYUPDATESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0038";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYDELETEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0039";
	static STRING_RESPONSECODE_CASE_GENERAL_RESPONSEHISTORYDELETESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0040";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYNOTFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0041";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0042";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYINSERTFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0043";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYINSERTSUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0044";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYUPDATEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0045";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYUPDATESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0046";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYDELETEFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0047";
	static STRING_RESPONSECODE_CASE_GENERAL_RESULTHISTORYDELETESUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0048";
	static STRING_RESPONSECODE_CASE_GENERAL_BUSINESSPROCESSFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0049";
	static STRING_RESPONSECODE_CASE_GENERAL_BUSINESSPROCESSSUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0050";
	static STRING_RESPONSECODE_CASE_GENERAL_EMAILSUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0051";
	static STRING_RESPONSECODE_CASE_GENERAL_EMAILFAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0052";
	static STRING_RESPONSECODE_CASE_GENERAL_SESSIONEXPIRED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0054";
	static STRING_RESPONSECODE_CASE_GENERAL_SERVICETOOLARGE: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL + "0055";

	//#endregion

	//#region HANDSHAKE

	static STRING_RESPONSECODE_MODULE_HANDSHAKE: string = "01";

	static STRING_RESPONSECODE_CASE_HANDSHAKE_KEYEMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_HANDSHAKE + "0101";
	static STRING_RESPONSECODE_CASE_HANDSHAKE_KEYINVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_HANDSHAKE + "0102";
	static STRING_RESPONSECODE_CASE_HANDSHAKE_USERIDEMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_HANDSHAKE + "0103";
	static STRING_RESPONSECODE_CASE_HANDSHAKE_USERIDINVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_HANDSHAKE + "0104";
	static STRING_RESPONSECODE_CASE_HANDSHAKE_TOKENEMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_HANDSHAKE + "0105";
	static STRING_RESPONSECODE_CASE_HANDSHAKE_TOKENINVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_HANDSHAKE + "0106";
	static STRING_RESPONSECODE_CASE_HANDSHAKE_TOKENLENGTH: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_HANDSHAKE + "0107";

	//#endregion

	//#region USER

	static STRING_RESPONSECODE_MODULE_USER: string = "02";

	static STRING_RESPONSECODE_CASE_USER_ID_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0101";
	static STRING_RESPONSECODE_CASE_USER_EMAIL_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0102";
	static STRING_RESPONSECODE_CASE_USER_PASSWORD_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0103";
	static STRING_RESPONSECODE_CASE_USER_PASSWORD_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0104";
	static STRING_RESPONSECODE_CASE_USER_PASSWORDNEW_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0105";
	static STRING_RESPONSECODE_CASE_USER_MOBILEPHONE_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0106";
	static STRING_RESPONSECODE_CASE_USER_EMAIL_DUPLICATE: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0107";
	static STRING_RESPONSECODE_CASE_USER_EMAILTOKEN_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0108";
	static STRING_RESPONSECODE_CASE_USER_EMAILTOKEN_EXPIRED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0109";
	static STRING_RESPONSECODE_CASE_USER_EMAILTOKEN_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0110";
	static STRING_RESPONSECODE_CASE_USER_MOBILEPHONE_DUPLICATE: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0111";
	static STRING_RESPONSECODE_CASE_USER_SMS_INVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0112";
	static STRING_RESPONSECODE_CASE_USER_SMS_SUCCESS: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0113";
	static STRING_RESPONSECODE_CASE_USER_SMS_FAIL: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0114";
	static STRING_RESPONSECODE_CASE_USER_MOBILETOKEN_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0115";
	static STRING_RESPONSECODE_CASE_USER_MOBILETOKEN_EXPIRED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0116";
	static STRING_RESPONSECODE_CASE_USER_MOBILETOKEN_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0117";
	static STRING_RESPONSECODE_CASE_USER_FIRSTNAME_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0118";
	static STRING_RESPONSECODE_CASE_USER_CHECKREGEX_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0119";
	static STRING_RESPONSECODE_CASE_USER_PASSWORDMINIMUM_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0120";
	static STRING_RESPONSECODE_CASE_USER_PASSWORDMAXIMUM_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0121";
	static STRING_RESPONSECODE_CASE_USER_VERIFIED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0122";
	static STRING_RESPONSECODE_CASE_USER_NOTFOUND: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0123";
	static STRING_RESPONSECODE_CASE_USER_BIRTHDAY_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0124";
	static STRING_RESPONSECODE_CASE_USER_GENDER_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0125";
	static STRING_RESPONSECODE_CASE_USER_SIGNUPID_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0126";
	static STRING_RESPONSECODE_CASE_USER_SIGNUPTYPE_INVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0127";
	static STRING_RESPONSECODE_CASE_USER_ACCOUNT_INVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0128";
	static STRING_RESPONSECODE_CASE_USER_PASSWORDSTRONG_INVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0129";
	static STRING_RESPONSECODE_CASE_USER_EMAILMINIMUM_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0130";
	static STRING_RESPONSECODE_CASE_USER_EMAILMAXIMUM_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0131";
	static STRING_RESPONSECODE_CASE_USER_EMAIL_INVALID: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0132";
	static STRING_RESPONSECODE_CASE_USER_MIDDLENAME_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0133";
	static STRING_RESPONSECODE_CASE_USER_LASTNAME_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0134";
	static STRING_RESPONSECODE_CASE_USER_PASSWORDCONFIRMATION_DIFFERENT: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0136";
	static STRING_RESPONSECODE_CASE_USER_BIRTHDAY_AGEMINIMUM: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0137";

	//#endregion

	//#region SEEKER BAN

	static STRING_RESPONSECODE_MODULE_SEEKERBAN: string = "02";

	static STRING_RESPONSECODE_CASE_SEEKERBAN_ID_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0101";
	static STRING_RESPONSECODE_CASE_SEEKERBAN_STARTDATE_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0102";
	static STRING_RESPONSECODE_CASE_SEEKERBAN_ENDDATE_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0103";
	static STRING_RESPONSECODE_CASE_SEEKERBAN_DURATION_FAILED: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0104";

	//#endregion

	//#region ADVERTISING DAILY

	static STRING_RESPONSECODE_MODULE_ADVERTISINGDAILY: string = "60";

	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_TITLE_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0101";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_CONTENT_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0102";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_FILE_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0103";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_FILE_FORMAT: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0104";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_FILE_SIZEMAXIMUM: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0105";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_NAME_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0106";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_STARTDATE_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0107";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_ENDDATE_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0108";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_STARTDATE_BEFORETODAY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0109";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_ENDDATE_BEFORESTARTDATE: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0110";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_SWIPE_MINIMUMVALUE: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0111";
	static STRING_RESPONSECODE_CASE_ADVERTISINGDAILY_LINK_EMPTY: string = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_USER + "0111";

	//#endregion

	/* SERVICE RESPONSE CODE - END */
}

//#endregion