<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Laporan</h2>
		<p>Laporan BRINS Safar</p>
    </header>

	<div id="divMainDashboard" class="DivMainAsideSearch">
		<form>
			<div class="DivForm">
				<h3>Pencarian</h3>
				<fieldset>
					<label for="datePeriod">Tanggal</label>
					<div>
						<mat-form-field appearance="fill" class="aside">
							<mat-label>input your report <span class="dashicons dashicons-text-page"></span></mat-label>
							<mat-date-range-input [rangePicker]="picker">
								<input matStartDate placeholder="Tanggal mulai" [value]="_modelProductGeneralRequest.PolicyStartDate" (dateChange)="getStartDate($event)">
								<input matEndDate placeholder="Tanggal mulai" [value]="_modelProductGeneralRequest.PolicyEndDate" (dateChange)="getEndDate($event)">
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
					</div>

				</fieldset>
			</div>
			<input type="button" name="buttonSearch" value="Cari" (click)="callSelectProductUmrahByAttributes(this)">
			<input type="button" name="buttonExport" value="Ekspor" (click)="callExportProductUmrahSyariah(this)">
		</form>

		<div class="DivTableContainer">
			<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>

			<table>
				<thead>
					<tr>
						<th>Tanggal Mulai Polis</th>
						<th>Tanggal Akhir Polis</th>
						<th>Nama Peserta</th>
						<th>Paket</th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let modelProductGeneral of _arrayProductGeneralModel">
						<td>{{ modelProductGeneral.PolicyStartDate | convertEmptyToDash | convertToDateShort }}</td>
						<td>{{ modelProductGeneral.PolicyEndDate | convertEmptyToDash | convertToDateShort }}</td>
						<td>{{ modelProductGeneral.modelProductUmrah?.InsuredName | convertEmptyToDash }}</td>
						<td>{{ modelProductGeneral.modelProductUmrah?.getCoverage() | convertEmptyToDash }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>