//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TravelSyariahModel } from "../models/travelsyariah.model";
import { UserModel } from "../models/user.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion

//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class TravelSyariahService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectTravelSyariahByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_TRAVELSYARIAH_SELECTTRAVELSYARIAHBYEMAIL);
	}

	//#endregion


	//#region VERIFY and SIGNOUT

	verifyTravelSyariahByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_TRAVELSYARIAH_VERIFYTRAVELSYARIAHBYEMAIL);
	}

	signOut(interfaceGeneralService: GeneralServiceInterface, modelTRAVELSYARIAH: TravelSyariahModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTRAVELSYARIAH), WebAddressConstant.STRING_URL_TRAVELSYARIAH_SIGNOUT);
	}

	//#endregion
}

//#endregion