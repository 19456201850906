//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_INTEGRATION_API: string = environment.URL_INTEGRATION_API;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORTRAVELSYARIAH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForTravelSyariah`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTDASHBOARDPRODUCTHAJJANDUMRAHFORTRAVEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardProductHajjAndUmrahForTravel`;

	//#endregion


	//#region TRAVEL SYARIAH

	static STRING_PATH_CONTROLLER_TRAVELSYARIAH: string = "/TravelSyariah";

	static STRING_URL_TRAVELSYARIAH_SELECTTRAVELSYARIAHBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TRAVELSYARIAH}/selectTravelSyariahByEmail`;
	static STRING_URL_TRAVELSYARIAH_VERIFYTRAVELSYARIAHBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TRAVELSYARIAH}/verifyTravelSyariahByEmail`;
	static STRING_URL_TRAVELSYARIAH_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TRAVELSYARIAH}/signOut`;

	static STRING_URL_TRAVELSYARIAH_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TRAVELSYARIAH}/selectChecker`;
	static STRING_URL_TRAVELSYARIAH_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TRAVELSYARIAH}/selectSigner`;
	static STRING_URL_TRAVELSYARIAH_SELECTTRAVELSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TRAVELSYARIAH}/selectTravelSyariahByToken`;

	//#endregion


	//#region PRODUCT UMRAH

	static STRING_PATH_CONTROLLER_PRODCUTUMRAH: string = "/ProductUmrah";

	static STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductByAttributes`;
	static STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBATCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductBatchByAttributes`;
	static STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYATTRIBUTESFORTRAVEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductByAttributesForTravel`;
	static STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYATTRIBUTESREPORTFORTRAVEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductByAttributesReportForTravel`;
	static STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBATCHBYATTRIBUTESFORTRAVEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductBatchByAttributesForTravel`;
	static STRING_URL_PRODCUT_SELECTPRODUCTFOREXPORTTRAVEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductForExportTravel`;
	static STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductByToken`;
	static STRING_URL_PRODCUTUMRAH_SELECTPRODUCTBYBATCHNUMBER: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/selectProductByBatchNumber`;
	static STRING_URL_PRODCUTUMRAH_VALIDATELISTPRODUCT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/validateListProduct`;
	static STRING_URL_PRODCUTUMRAH_UPLOADPRODUCTATTACHMENT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/uploadProductAttachment`;
	static STRING_URL_PRODCUTUMRAH_DOWNLOADPRODUCTATTACHMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/downloadProductAttachmentByToken`;
	static STRING_URL_PRODCUTUMRAH_DOWNLOADCONTRIBUTIONNOTEBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/downloadContributionNoteByToken`;
	static STRING_URL_PRODCUTUMRAH_DOWNLOADCOMISSIONNOTEBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/downloadComissionNoteByToken`;
	static STRING_URL_PRODCUTUMRAH_DOWNLOADCOVERAGEOVERVIEWBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/downloadCoverageOverviewByToken`;
	static STRING_URL_PRODCUTUMRAH_INSERTLISTPRODUCT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/insertListProduct`;
	static STRING_URL_PRODCUTUMRAH_INSERTPRODUCT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/insertProduct`;
	static STRING_URL_PRODCUTUMRAH_APPROVEPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/approveProductByToken`;
	static STRING_URL_PRODCUTUMRAH_REJECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTUMRAH}/rejectProductByToken`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region POSTAL

	static STRING_PATH_CONTROLLER_POSTAL: string = "/Postal";

	static STRING_URL_POSTAL_VALIDATEPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/validatePostalCode`;
	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCode`;

	//#endregion


	//#region TRAVEL

	static STRING_PATH_CONTROLLER_TRAVEL: string = "/Travel";

	static STRING_URL_TRAVEL_SELECTTRAVELBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TRAVEL}/selectTravelByToken`;

	//#endregion


	//#region OCCUPATION

	static STRING_PATH_CONTROLLER_OCCUPATION: string = "/Occupation";

	static STRING_URL_OCCUPATION_SELECTOCCUPATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OCCUPATION}/selectOccupation
	`;

	//#endregion
}

//#endregion