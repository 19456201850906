//#region IMPORT

import { sha256 } from "../../../node_modules/js-sha256";
import "./extension.function";
import { HandshakeModel } from "../models/handshake.model";
import JSEncrypt from "../../../node_modules/jsencrypt";
import AESJS from "../../../node_modules/aes-js";

//#endregion


//#region CLASS

export class CryptographyFunction
{
	//#region DECLARATION

	private _encryptAsymmetric: any;
	private _decryptAsymmetric: any;


	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		this._encryptAsymmetric = new JSEncrypt({default_key_size: "1024",
			default_public_exponent: "010001",
			log: false});
		this._decryptAsymmetric = new JSEncrypt({default_key_size: "1024",
			default_public_exponent: "010001",
			log: false});
	}

	//#endregion


	//#region ASSYMETRIC

	/*
		generateKey - START
		Description : This function for generate key of RSA also returned private key and public key.
		Refrence: https://github.com/travist/jsencrypt/blob/master/demo/index.html
		Author : Komang Suryadana and Ibrahim Aziz.
		Created on : Thursday, 23 August 2018.		Updated on : Wednesday, 4 March 2020.
		Created by : Komang Suryadana.				Updated by : Komang Suryadana.
		Version : 1.0:2.
	*/

	generateKey(): HandshakeModel
	{
		// Initialitation JSEncrypt and generating key

		const encryptJS: any = new JSEncrypt({default_key_size: "1024",
			default_public_exponent: "010001",
			log: false});
		encryptJS.getKey();

		// Getting privateKey and publicKey

		const stringPrivateKey: string = encryptJS.getPrivateKey();
		const stringPublicKey: string = encryptJS.getPublicKey();

		const modelHandshake: HandshakeModel = new HandshakeModel();
		modelHandshake.RequestKey = stringPublicKey;
		modelHandshake.ResponseKey = stringPrivateKey;

		return modelHandshake;
	}

	/* generateKey - END */

	/*
		encryptAsymmetric - START
		Description : Encrypt assymetric encryption with public key.
		Refrence: https://github.com/travist/jsencrypt/blob/master/demo/index.html
		Author : Komang Suryadana.
		Created on : Thursday, 23 August 2018.		Updated on : Wednesday, 05 September 2018.
		Created by : Komang Suryadana.				Updated by : Komang Suryadana.
		Version : 1.0:1.
	*/

	encryptAsymmetric(stringRaw: string, stringPublicKey: string): string
	{
		this._encryptAsymmetric.setPublicKey(stringPublicKey);
		const stringResult: string = this._encryptAsymmetric.encrypt(stringRaw);
		return stringResult;
	}

	/* encryptAsymmetric - END */

	/*
		decryptAsymmetric - START
		Description : Decrypt asymmetric encryption with private key.
		Refrence: https://github.com/travist/jsencrypt/blob/master/demo/index.html
		Author : Komang Suryadana.
		Created on : Thursday, 23 August 2018.		Updated on : Wednesday, 05 September 2018.
		Created by : Komang Suryadana.				Updated by : Komang Suryadana.
		Version : 1.0:1.
	*/

	decryptAsymmetric(stringRaw: string, stringPrivateKey: string): string
	{
		this._decryptAsymmetric.setPrivateKey(stringPrivateKey);
		const stringResult: string = this._decryptAsymmetric.decrypt(stringRaw);
		return stringResult;
	}

	/* decryptAsymmetric - END */

	//#endregion


	//#region SYMMETRIC

	/*
		encryptSymmetric - START
		Description : This function for encrypting string with AES cryptography on this case use CBC mode and PKCS 7 padding then converted to base 64.
		Refrence: https://github.com/ricmoo/aes-js, https://github.com/ricmoo/aes-js/issues/16
		Author : Komang Suryadana and Ibrahim Aziz.
		Created on : Thu, 23 August 2018.        Updated on : Thursday, 27 February 2020.
		Created by : Komang Suryadana.           Updated by : Ibrahim Aziz.
		Version : 1.0:3.
	*/

	encryptSymmetric(stringRaw: string, stringKey: string): string
	{
		const arrayIV: Array<number> = stringKey.convertToUTF8Bytes();
		const arrayKey: Array<number> = sha256.array(arrayIV);
		const arrayRaw: Array<number> = stringRaw.convertToUTF8Bytes();

		const aesCBC = new AESJS.ModeOfOperation.cbc(arrayKey, arrayIV);

		const arrayEncrypted: Array<number> = aesCBC.encrypt(AESJS.padding.pkcs7.pad(arrayRaw));
		const stringEncoded: string = this.convertUTF8BytesToBase64(arrayEncrypted);

		return stringEncoded;
	}

	convertUTF8BytesToBase64(arrayBytes: Array<number>): string
	{
		let stringBinary: string = "";
		const arrayUInt8Bytes: Uint8Array = new Uint8Array(arrayBytes);
		const numberArrayUInt8BytesLength: number = arrayUInt8Bytes.byteLength;

		for (let numberIndex = 0; numberIndex < numberArrayUInt8BytesLength; numberIndex++)
		{
			stringBinary += String.fromCharCode(arrayUInt8Bytes[numberIndex]);
		}

		return window.btoa(stringBinary);
	}

	/* encryptSymmetric - END */

	/*
		decryptSymmetric - START
		Description : This function for decrypting from string of base 64 with AES cryptography on this case use CBC mode and PKCD 7 padding.
		Refrence: https://github.com/ricmoo/aes-js, https://github.com/ricmoo/aes-js/issues/16
		Author : Komang Suryadana and Ibrahim Aziz.
		Created on : Thu, 23 August 2018.        Updated on : Tuesday, 5 January 2020.
		Created by : Komang Suryadana.           Updated by : Ibrahim Aziz.
		Version : 1.0:4.
	*/

	decryptSymmetric(stringEncoded: string, stringKey: string): string
	{
		const arrayIV: Array<number> = stringKey.convertToUTF8Bytes();
		const arrayKey: Array<number> = sha256.array(arrayIV);
		const arrayEncrypted: Array<number> = stringEncoded.convertBase64ToUTF8Bytes();

		const aesCBC = new AESJS.ModeOfOperation.cbc(arrayKey, arrayIV);

		const arrayDecrypted: ArrayBuffer = AESJS.padding.pkcs7.strip(aesCBC.decrypt(arrayEncrypted));
		const stringDecrypted: string = this.convertUTF8BytesToString(arrayDecrypted);

		return stringDecrypted;
	}

	convertUTF8BytesToString(arrayBytes: ArrayBuffer): string
	{
		return new TextDecoder("utf-8").decode(arrayBytes);

		// return String.fromCharCode.apply(String, new Uint8Array(this));
	}

	/* decryptSymmetric - END */


	//#region HASH

	/*
		hashWithSHA256 - START
		Description : Encrypt symmetric with authorize key or default key.
		Refrence: https://github.com/ricmoo/aes-js, https://github.com/ricmoo/aes-js/issues/16
		Author : Ibrahim Aziz.
		Created on : Monday, 22 April 2019.			Updated on : Thursday, 27 February 2020.
		Created by : Ibrahim Aziz.					Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	hashWithSHA256(stringRaw: string): string
	{
		try
		{
			return sha256(stringRaw).toUpperCase();
		}
		catch (error)
		{
			return "";
		}
	}

	/* hashWithSHA256 - END */

	//#endregion
}

//#endregion