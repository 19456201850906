<div id="divBodyHome">
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelTravelSyariahSignIn.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelTravelSyariahSignIn.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">{{ _modelTravelSyariahSignIn.getPositionName() }}</span>
			</figcaption>

			<a class="Underline SignOut" (click)="callSignOut();">Keluar</a>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<a (click)="goToDashboard();">Dasbor</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToSubmission();">Permohonan</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToReport();">Laporan</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToAbout();">Tentang</a>
			</li>
		</ul>

		<div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
			<div>
				<span>Travel Syariah</span>
				<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Hindari kerumitan untuk kembali ke aplikasi ini dan memberi Anda pengalaman yang lebih baik!</p>
				<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">Untuk menginstal aplikasi web ini di perangkat Anda, ketuk tombol 'Bagikan' dan kemudian tombol 'Tambahkan ke Layar Utama'.</p>
			</div>

			<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
		</div>

		<a href="https://www.ssltrust.com.au/security-report?domain=bsi.syariah.web.brinesia.app" rel="nofollow" target="new"><img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a>

		<iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0"></iframe>

		<table width="135" border="0" cellpadding="2" cellspacing="0" title="Click to Verify - This site chose DigiCert SSL for secure e-commerce and confidential communications."><tr><td width="135" align="center" valign="top"><script type="text/javascript" src="https://seal.websecurity.norton.com/getseal?host_name=jlo.web.brinesia.app&amp;size=L&amp;use_flash=NO&amp;use_transparent=No&amp;lang=en"></script><br /><a href="https://www.digicert.com/what-is-ssl-tls-https/" target="_blank"  style="color:#000000; text-decoration:none; font:bold 10px verdana,sans-serif; letter-spacing:.5px;text-align:center; margin:0px; padding:0px;"> How SSL Secures You</a></td></tr></table>

		<!--- DO NOT EDIT - GlobalSign SSL Site Seal Code - DO NOT EDIT ---><table width=125 border=0 cellspacing=0 cellpadding=0 title="CLICK TO VERIFY: This site uses a GlobalSign SSL Certificate to secure your personal information." ><tr><td><span id="ss_img_wrapper_gmogs_image_125-50_en_dblue"><a href="https://www.globalsign.com/" target="_blank" title="GlobalSign Site Seal" rel="nofollow"><img alt="SSL" border=0 id="ss_img" src="//seal.globalsign.com/SiteSeal/images/gs_noscript_125-50_en.gif"></a></span><script type="text/javascript" src="//seal.globalsign.com/SiteSeal/gmogs_image_125-50_en_dblue.js"></script></td></tr></table><!--- DO NOT EDIT - GlobalSign SSL Site Seal Code - DO NOT EDIT --->

		<a href="https://www.gogetssl.com" rel="nofollow" title="GoGetSSL Site Seal Logo" ><div id="gogetssl-animated-seal" style="width:180px; height:58px;"></div></a> <script src="https://gogetssl-cdn.s3.eu-central-1.amazonaws.com/site-seals/gogetssl-seal.js"></script>

		<script type="text/javascript"> //<![CDATA[
			var tlJsHost = ((window.location.protocol == "https:") ? "https://secure.trust-provider.com/" : "http://www.trustlogo.com/");
			document.write(unescape("%3Cscript src='" + tlJsHost + "trustlogo/javascript/trustlogo.js' type='text/javascript'%3E%3C/script%3E"));
		  //]]></script>
		<script language="JavaScript" type="text/javascript">
			TrustLogo("https://ssl.comodo.com/images/seals/sectigo_trust_seal_lg_2x.png", "SECEV", "none");
		</script>

		<script type="text/javascript"> //<![CDATA[
			var tlJsHost = ((window.location.protocol == "https:") ? "https://secure.trust-provider.com/" : "http://www.trustlogo.com/");
			document.write(unescape("%3Cscript src='" + tlJsHost + "trustlogo/javascript/trustlogo.js' type='text/javascript'%3E%3C/script%3E"));
		//]]></script>
		<script language="JavaScript" type="text/javascript">
			TrustLogo("https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png", "POSDV", "none");
		</script>

		<a href="https://www.gogetssl.com" rel="nofollow" title="GoGetSSL Site Seal Logo" ><div id="gogetssl-animated-seal" style="width:180px; height:58px;"></div></a> <script src="https://gogetssl-cdn.s3.eu-central-1.amazonaws.com/site-seals/gogetssl-seal.js"></script>

		<script type="text/javascript" referrerpolicy="origin" src="https://seal.securetrust.com/seal.js?style=invert"></script>

		<div id="DigiCertClickID_f8wBj4Ja" lang="es"></div>
		<script type="text/javascript" id="scriptDigiCert">
			var __dcid = __dcid || [];
			__dcid.push({"cid":"DigiCertClickID_f8wBj4Ja","tag":"f8wBj4Ja"});
			(function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = 
		document.getElementById("scriptDigiCert");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
		</script>
	</nav>

	<main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
		<router-outlet></router-outlet>
	</main>

	<input type="button" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" src="../../assets/logos/sakina/logo_syariah.svg" alt="BRI Insurance - Logo Header">
</div>