//#region CLASS

export class RuleConstant
{
	//#region FORM

	static NUMBER_EMAILTOKEN_LENGTH: number = 6;
	static NUMBER_EMAILTOKENSINGLE_LENGTH: number = 1;
	static NUMBER_SEARCH_LENGTHMINIMUM: number = 3;
	static NUMBER_POSTALCODE_LENGTH: number = 5;
	static NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH: number = 16;
	static NUMBER_PASSPORTNUMBERMIN_NUMBER: number = 6;
	static NUMBER_PASSPORTNUMBERMAX_NUMBER: number = 12;
	static NUMBER_FLIGHTNUMBERMIN_NUMBER: number = 3;
	static NUMBER_FLIGHTNUMBERMAX_NUMBER: number = 10;
	static NUMBER_YEAR_LENGTH: number = 4;
	static NUMBER_AGE_VALUEMINIMUM: number = 17;
	static NUMBER_FIRSTNAME_LENGTHMINIMUM: number = 3;
	static NUMBER_MOBILEPHONE_LENGTHMINIMUM: number = 10;
	static NUMBER_TAXNUMBER_LENGTH: number = 15;
	static NUMBER_DASHBOARDDATEPERIOD_RANGE: number = 6;
	static NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_SILVER: number = 6;
	static NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_SILVER: number = 14;
	static NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_GOLD: number = 15;
	static NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_GOLD: number = 19;
	static NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_PLATINUM: number = 20;
	static NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_PLATINUM: number = 29;
	static NUMBER_DASHBOARDDATEPERIOD_MONTH: number = 30;

	//#endregion


	//#region PRODUCT

	static NUMBER_BUILDINGPRICE_VALUEMINIMUM: number = 1000000.0;
	static NUMBER_INTERIORPRICE_VALUEMINIMUM: number = 100000.0;
	static NUMBER_BUILDINGADDRESS_LENGTHMINIMUM: number = 10;
	static NUMBER_REFERENCENUMBER_LENGTH: number = 18;
	static NUMBER_PRODUCTSTANDART_SILVER: number = 50000;
	static NUMBER_PRODUCTPERSONALACCIDENT_SILVER: number = 75000;
	static NUMBER_PRODUCTSTANDART_GOLD: number = 75000;
	static NUMBER_PRODUCTPERSONALACCIDENT_GOLD: number = 100000;
	static NUMBER_PRODUCTSTANDART_PLATINUM: number = 150000;
	static NUMBER_PRODUCTPERSONALACCIDENT_PLATINUM: number = 175000;

	//#endregion


	//#region UPLOAD

	static NUMBER_FILE_COMPRESS_SIZEMAXIMUM: number = 50000000.0;
	static NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM: number = 50000000.0;
	static NUMBER_FILE_COMPRESS_IMAGE_SIZEMAXIMUM: number = 10000000.0;
	static NUMBER_FILE_NONCOMPRESS_IMAGE_SIZEMAXIMUM: number = 10000000.0;
	static NUMBER_FILE_NONCOMPRESS_EXCEL_SIZEMAXIMUM: number = 50000000.0;
	static NUMBER_FILE_TOTAL_SIZEMAXIMUM: number = 100000000.0;

	static STRING_FILE_TYPE_EXCEL: string = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv";
	static STRING_FILE_TYPE_IMAGE: string = "image/jpeg, image/jpeg, image/png";

	//#endregion
}

//#endregion