//#region IMPORT

import { ENUM_CITIZENSHIP_STATUS, ENUM_GENDER_TYPE, ENUM_MARITAL_STATUS, ENUM_PRODUCTUMRAH_STATUS, ENUM_RESPONSE_STATE, ENUM_UMRAH_PERIOD } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";
import { FileDocumentModel } from "./filedocument.model";
import { ProductUmrahAttachmentModel } from "./productumrahattachment.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class ProductUmrahModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for Product Umrah model.
		Author: Ibrahim Aziz.
		Created on : Thrusday, 9 February 2023.			    Updated on : Thrusday, 9 February 2023.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:0.
	*/

	ID?: number;
	ProductGeneralID?: number;
	TravelID?: number;
	TravelName?: string;
	CoverageCode?: string;
	Token?: string;

	// INSURED

	InsuredIdentificationNumber?: string;
	InsuredName?: string;
	InsuredBirthDate?: Date;
	InsuredBirthPlace?: string;
	InsuredGender?: ENUM_GENDER_TYPE;
	InsuredAddress?: string;
	InsuredEmail?: string;
	InsuredPhoneNumber?: string;
	InsuredPassportNumber?: string;
	InsuredFlightNumber?: string;
	InsuredDepartureDate?: Date;
	InsuredArrivalDate?: Date;
	InsuredOccupation?: string;
	InsuredCitizenship?: ENUM_CITIZENSHIP_STATUS;
	InsuredMaritalStatus?: ENUM_MARITAL_STATUS;
	RiskLocation?: string;
	BranchCode?: string;
	Status?: ENUM_PRODUCTUMRAH_STATUS;
	PeriodType?: ENUM_UMRAH_PERIOD;
	ReferenceNumber?: string;
	PolicyRegistrationNumber?: string;
	PolicyHolder?: string;
	HolderName?: string;
	InsuredID?: string;
	PolicyNo?: string;
	RefNo?: string;
	TOC?: string;
	Branch?: string;
	Segment?: string;
	MasterF?: number;
	ISType?: string;
	SType?: string;
	RFLDID?: string;
	Lighting?: string;
	PolicyWording?: string;
	LeaderID?: string;
	LeaderShare?: string;
	LeaderFee?: string;
	LeaderHandlingFee?: string;
	MemberID1?: string;
	MemberShare1?: string;
	MemberComm1?: string;
	MemberHf1?: string;
	MemberPCollectionf1?: string;
	MemberCollectionf1?: string;
	MemberID2?: string;
	MemberShare2?: string;
	MemberComm2?: string;
	MemberHf2?: string;
	MemberPCollectionf2?: string;
	MemberCollectionf2?: string;
	MemberID3?: string;
	MemberShare3?: string;
	MemberComm3?: string;
	MemberHf3?: string;
	MemberPCollectionf3?: string;
	MemberCollectionf3?: string;
	MemberID4?: string;
	MemberShare4?: string;
	MemberComm4?: string;
	MemberHf4?: string;
	MemberPCollectionf4?: string;
	MemberCollectionf4?: string;
	MemberID5?: string;
	MemberShare5?: string;
	MemberComm5?: string;
	MemberHf5?: string;
	MemberPCollectionf5?: string;
	MemberCollectionf5?: string;
	RateCode1?: string;
	Rate1?: number;
	AddUnit1?: string;
	PCALC1?: string;
	RateCode2?: string;
	Rate2?: string;
	AddUnit2?: string;
	PCALC2?: string;
	RateCode3?: string;
	Rate3?: string;
	AddUnit3?: string;
	PCALC3?: string;
	RateCode4?: string;
	Rate4?: string;
	AddUnit4?: string;
	PCALC4?: string;
	RateCode5?: string;
	Rate5?: string;
	AddUnit5?: string;
	PCALC5?: string;
	InterestCode1?: string;
	InterestRemark1?: string;
	AddCurrency1?: string;
	InterestCode2?: string;
	InterestRemark2?: string;
	AddCurrency2?: string;
	TSI2?: string;
	InterestCode3?: string;
	InterestRemark3?: string;
	AddCurrency3?: string;
	TSI3?: string;
	InterestCode4?: string;
	InterestRemark4?: string;
	AddCurrency4?: string;
	TSI4?: string;
	InterestCode5?: string;
	InterestRemark5?: string;
	AddCurrency5?: string;
	TSI5?: string;
	FeeID?: string;
	FeeRemark?: string;
	FeeCurrency?: string;
	FeeAmount?: string;
	DutyID?: string;
	DutyRemark?: string;
	DutyCurrency?: string;
	DutyAmount?: string;
	BrokerAgent1?: string;
	BrokerAgentType1?: string;
	BrokerAgentFee1?: string;
	BrokerAgent12?: string;
	BrokerAgentType2?: string;
	BrokerAgentFee2?: number;
	BrokerAgent3?: string;
	BrokerAgentType3?: string;
	BrokerAgentFee3?: number;
	Deductible1?: string;
	Dremarks1?: string;
	DedCur1?: string;
	DedAmountMin1?: string;
	DedAmountMax1?: string;
	DedOfTSI1?: string;
	DedOfCL1?: string;
	Deductible2?: string;
	Dremarks2?: string;
	DedCur2?: string;
	DedAmountMin2?: string;
	DedAmountMax2?: string;
	DedOfTSI2?: string;
	DedOfCL2?: string;
	Deductible3?: string;
	Dremarks3?: string;
	DedCur3?: string;
	DedAmountMin3?: string;
	DedAmountMax3?: string;
	DedOfTSI3?: string;
	DedOfCL3?: string;
	Deductible4?: string;
	Dremarks4?: string;
	DedCur4?: string;
	DedAmountMin4?: string;
	DedAmountMax4?: string;
	DedOfTSI4?: string;
	DedOfCL4?: string;
	Deductible5?: string;
	Dremarks5?: string;
	DedCur5?: string;
	DedAmountMin5?: string;
	DedAmountMax5?: string;
	DedOfTSI5?: string;
	DedOfCL5?: string;

	modelProductUmrahAttachment?: ProductUmrahAttachmentModel;
	listModelProductUmrahAttachment?: Array<ProductUmrahAttachmentModel>;


	/* Attribute - END */


	constructor()
	{
		super();
		this.listModelProductUmrahAttachment = [];
	}


	//#region GETTER

	getStatus(): string
	{
		if (this.Status == null || this.Status === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (this.Status === ENUM_PRODUCTUMRAH_STATUS.PendingForApproval)
		{
			return "Menunggu Persetujuan";
		}
		else if (this.Status === ENUM_PRODUCTUMRAH_STATUS.Rejected)
		{
			return "Ditolak";
		}
		else if (this.Status === ENUM_PRODUCTUMRAH_STATUS.Approved)
		{
			return "Disetujui";
		}
		else
		{
			return ENUM_PRODUCTUMRAH_STATUS[this.Status];
		}
	}

	getCoverage(): string
	{
		if (this.CoverageCode === null || this.CoverageCode === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (this.CoverageCode === StringConstant.STRING_FIELD_COVERAGE_STANDART)
		{
			return "Standar";
		}
		else if(this.CoverageCode === StringConstant.STRING_FIELD_COVERAGE_PLUSPASTANDART)
		{
			return "Plus PA Standar";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getPeriod(): string
	{
		if (this.PeriodType === null || this.PeriodType === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (this.PeriodType === ENUM_UMRAH_PERIOD.FirstPeriod)
		{
			return "9 - 15 hari";
		}
		else if(this.PeriodType === ENUM_UMRAH_PERIOD.SecondPeriod)
		{
			return "16 - 20 hari";
		}
		else if(this.PeriodType === ENUM_UMRAH_PERIOD.ThirdPeriod)
		{
			return "21 - 30 hari";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getMartialStatus(): string
	{
		if (this.InsuredMaritalStatus === null || this.InsuredMaritalStatus === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (this.InsuredMaritalStatus === ENUM_MARITAL_STATUS.Single)
		{
			return "Belum Kawin";
		}
		else if(this.InsuredMaritalStatus === ENUM_MARITAL_STATUS.Married)
		{
			return "Kawin";
		}
		else if(this.InsuredMaritalStatus === ENUM_MARITAL_STATUS.Divorce)
		{
			return "Cerai Hidup";
		}
		else if(this.InsuredMaritalStatus === ENUM_MARITAL_STATUS.Widow)
		{
			return "Cerai Mati";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getGender(): string
	{
		if (this.InsuredGender === null || this.InsuredGender === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (this.InsuredGender === ENUM_GENDER_TYPE.Male)
		{
			return "Laki-laki";
		}
		else if(this.InsuredGender === ENUM_GENDER_TYPE.Female)
		{
			return "Perempuan";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getCitizenship(): string
	{
		if (this.InsuredCitizenship === null || this.InsuredCitizenship === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (this.InsuredCitizenship === ENUM_CITIZENSHIP_STATUS.Indonesian)
		{
			return "WNI";
		}
		else if(this.InsuredCitizenship === ENUM_CITIZENSHIP_STATUS.Foreign)
		{
			return "WNA";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion


	//#region SETTER

	setListModelProductUmrahAttachment(arrayModelProductUmrahAttachment?: Array<ProductUmrahAttachmentModel>): void
	{
		if (arrayModelProductUmrahAttachment == null || arrayModelProductUmrahAttachment === undefined)
		{

		}
		else
		{
			if (arrayModelProductUmrahAttachment.length > 0)
			{
				let modelProductUmrahAttachment: ProductUmrahAttachmentModel;
				this.listModelProductUmrahAttachment = [];

				for (const modelProductUmrahAttachmentTemporary of arrayModelProductUmrahAttachment)
				{
					modelProductUmrahAttachment = new ProductUmrahAttachmentModel();
					modelProductUmrahAttachment.setModelFromObject(modelProductUmrahAttachmentTemporary);
					this.listModelProductUmrahAttachment?.push(modelProductUmrahAttachment);
				}
			}
			else
			{

			}
		}
	}

	//#endregion


	//#region VALIDATION

	validateCheckListModelProductUmrahAttachment(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Umrah");

		if(this.listModelProductUmrahAttachment == null || this.listModelProductUmrahAttachment === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Pengisian Sudah Benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForProductUmrah(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Umrah");

		if (ProductUmrahModel == null || ProductUmrahModel === undefined)
		{
			modelResponse.MessageContent = "Model Product Umrah tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Pengisian Sudah Benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateProductUmrahForInsert(): ResponseModel
	{
		const regExAplhaNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);
		const regExPersonName: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_PERSONNAME);
		const regExAplhaWithSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETWITHSPACE);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Umrah");

		if (this.InsuredName == null || this.InsuredName === undefined || this.InsuredName === "")
		{
			modelResponse.MessageContent = "Nama Peserta tidak boleh kosong! Silahkan isi!";
		}
		else if (!regExPersonName.test(this.InsuredName))
		{
			modelResponse.MessageContent = "Nama Peserta hanya boleh huruf dan numerik.";
		}
		else if (this.InsuredGender == null || this.InsuredGender === undefined)
		{
			modelResponse.MessageContent = "Jenis Kelamin tidak boleh kosong! Silahkan isi!";
		}
		else if (this.InsuredBirthDate == null || this.InsuredBirthDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal Lahir tidak boleh kosong! Silahkan isi!!";
		}
		else if (this.InsuredBirthPlace == null || this.InsuredBirthPlace === undefined || this.InsuredBirthPlace === "")
		{
			modelResponse.MessageContent = "Tempat Lahir tidak boleh kosong! Silahkan isi!";
		}
		else if (!regExPersonName.test(this.InsuredBirthPlace))
		{
			modelResponse.MessageContent = "Tempat Lahir hanya boleh huruf dan numerik.";
		}
		else if (this.PeriodType == null || this.PeriodType === undefined)
		{
			modelResponse.MessageContent = "Periode tidak boleh kosong! Silahkan isi!!";
		}
		else if (this.InsuredPassportNumber == null || this.InsuredPassportNumber === undefined || this.InsuredPassportNumber === "")
		{
			modelResponse.MessageContent = "Nomor Paspor tidak boleh kosong! Silahkan isi!!";
		}
		else if (!regExAplhaNumeric.test(this.InsuredPassportNumber))
		{
			modelResponse.MessageContent = "Nomor paspor hanya boleh huruf dan numerik.";
		}
		else if (this.InsuredPassportNumber.length < RuleConstant.NUMBER_PASSPORTNUMBERMIN_NUMBER)
		{
			modelResponse.MessageContent = "Nomor paspor minimal "+ RuleConstant.NUMBER_PASSPORTNUMBERMIN_NUMBER+" digit";
		}
		else if (this.InsuredPassportNumber.length > RuleConstant.NUMBER_PASSPORTNUMBERMAX_NUMBER)
		{
			modelResponse.MessageContent = "Nomor paspor maksimal "+ RuleConstant.NUMBER_PASSPORTNUMBERMAX_NUMBER+ " digit";
		}
		else if (this.InsuredCitizenship == null || this.InsuredCitizenship === undefined)
		{
			modelResponse.MessageContent = "Kewarganegaraan tidak boleh kosong! Silahkan isi!!";
		}
		else if (this.InsuredOccupation == null || this.InsuredOccupation === undefined || this.InsuredOccupation === "")
		{
			modelResponse.MessageContent = "Pekerjaan tidak boleh kosong! Silahkan isi!!";
		}
		else if (!regExAplhaWithSpace.test(this.InsuredOccupation))
		{
			modelResponse.MessageContent = "Pekerjaan hanya boleh huruf.";
		}
		else if (this.InsuredMaritalStatus == null || this.InsuredMaritalStatus === undefined)
		{
			modelResponse.MessageContent = "Status Perkawinan tidak boleh kosong! Silahkan isi!!";
		}
		else if (this.InsuredDepartureDate === null || this.InsuredDepartureDate === undefined && this.InsuredArrivalDate === null || this.InsuredArrivalDate === undefined)
		{
			modelResponse.MessageContent = "Periode Keberangkatan & Kepulangan tidak boleh kosong! Silahkan isi!";
		}
		else if (this.CoverageCode === null || this.CoverageCode === undefined)
		{
			modelResponse.MessageContent = "Jenis Paket tidak boleh kosong! Silahkan isi!";
		}
		else if (this.InsuredFlightNumber === null || this.InsuredFlightNumber === undefined || this.InsuredFlightNumber === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.InsuredFlightNumber !== null || this.InsuredFlightNumber !== undefined || this.InsuredFlightNumber !== "")
		{
			if (!regExAplhaNumeric.test(this.InsuredFlightNumber))
			{
				modelResponse.MessageContent = "Nomor penerbangan hanya boleh huruf dan angka.";
			}
			else if (this.InsuredFlightNumber.length < RuleConstant.NUMBER_FLIGHTNUMBERMIN_NUMBER)
			{
				modelResponse.MessageContent = "Nomor penerbangan minimal "+ RuleConstant.NUMBER_FLIGHTNUMBERMIN_NUMBER+" digit";
			}
			else if (this.InsuredFlightNumber.length > RuleConstant.NUMBER_FLIGHTNUMBERMAX_NUMBER)
			{
				modelResponse.MessageContent = "Nomor penerbangan maksimal "+ RuleConstant.NUMBER_FLIGHTNUMBERMAX_NUMBER+ " digit";
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}


		return modelResponse;
	}

	validateArrayFileDocumentInsert(arrayFileDocument: Array<FileDocumentModel>): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dokument Bukti Bayar");

		if (arrayFileDocument === null || arrayFileDocument === undefined || arrayFileDocument.length <= 0)
		{
			modelResponse.MessageContent = "Bukti pembayaran tidak boleh kosong, silahkan unggah bukti pembayaran!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validatePeriodSilver(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Periode");

		if (this.InsuredDepartureDate == null || this.InsuredDepartureDate === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Tanggal keberangkatan tidak boleh kosong.";
		}
		else if (this.InsuredArrivalDate == null || this.InsuredArrivalDate === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Tanggal kepulangan tidak boleh kosong.";
		}
		else if (this.InsuredArrivalDate <= this.InsuredDepartureDate)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Tanggal kepulangan tidak boleh dari keberangkatan.";
		}
		else if (this.InsuredArrivalDate.calculateDayDifference(this.InsuredDepartureDate) > RuleConstant.NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_SILVER)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Periode tidak boleh lebih dari " + (RuleConstant.NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_SILVER + 1) + " hari.";
		}
		else if (this.InsuredArrivalDate.calculateDayDifference(this.InsuredDepartureDate) < RuleConstant.NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_SILVER)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Periode tidak boleh kurang dari " + (RuleConstant.NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_SILVER + 1) + " hari.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validatePeriodGold(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Periode");

		if (this.InsuredDepartureDate == null || this.InsuredDepartureDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal keberangkatan tidak boleh kosong.";
		}
		else if (this.InsuredArrivalDate == null || this.InsuredArrivalDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal kepulangan tidak boleh kosong.";
		}
		else if (this.InsuredArrivalDate <= this.InsuredDepartureDate)
		{
			modelResponse.MessageContent = "Tanggal kepulangan tidak boleh dari keberangkatan.";
		}
		else if (this.InsuredArrivalDate.calculateDayDifference(this.InsuredDepartureDate) > RuleConstant.NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_GOLD)
		{
			modelResponse.MessageContent = "Periode tidak boleh lebih dari " + (RuleConstant.NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_GOLD + 1) + " hari.";
		}
		else if (this.InsuredArrivalDate.calculateDayDifference(this.InsuredDepartureDate) < RuleConstant.NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_GOLD)
		{
			modelResponse.MessageContent = "Periode tidak boleh kurang dari " + (RuleConstant.NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_GOLD + 1) + " hari.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validatePeriodPlatinum(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Periode");

		if (this.InsuredDepartureDate == null || this.InsuredDepartureDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal keberangkatan tidak boleh kosong.";
		}
		else if (this.InsuredArrivalDate == null || this.InsuredArrivalDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal kepulangan tidak boleh kosong.";
		}
		else if (this.InsuredArrivalDate <= this.InsuredDepartureDate)
		{
			modelResponse.MessageContent = "Tanggal kepulangan tidak boleh dari keberangkatan.";
		}
		else if (this.InsuredArrivalDate.calculateDayDifference(this.InsuredDepartureDate) > RuleConstant.NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_PLATINUM)
		{
			modelResponse.MessageContent = "Periode tidak boleh lebih dari " + (RuleConstant.NUMBER_SUBMISSIONDATEPERIODMAX_RANGE_PLATINUM + 1) + " hari.";
		}
		else if (this.InsuredArrivalDate.calculateDayDifference(this.InsuredDepartureDate) < RuleConstant.NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_PLATINUM)
		{
			modelResponse.MessageContent = "Periode tidak boleh kurang dari " + (RuleConstant.NUMBER_SUBMISSIONDATEPERIODMIN_RANGE_PLATINUM + 1) + " hari.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}


	//#endregion
}

//#endregion