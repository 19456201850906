/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_MARITAL_STATUS, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS, ENUM_UMRAH_PERIOD } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { NumberConstant } from "../constants/number.constant";
import { BaseModel } from "./bases/base.model";
import { FileDocumentModel } from "./filedocument.model";
import { ProductUmrahModel } from "./productumrah.model";
import { ResponseModel } from "./response.model";
import { ValidateModel } from "./excelvalidate.model";

//#endregion


//#region CLASS

export class ProductGeneralModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product general model, used for contain general information to cover specific product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.		    	Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductBatchID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: string;
	CustomerID?: string;
	RelationshipManagerID?: number;
	RelationshipManagerName?: string;
	RelationshipManagerEmail?: string;
	RelationshipManagerMobilePhone?: string;
	RelationshipManagerCoreID?: string;
	Disclaimer?: boolean;
	SurveyID?: number;
	Note?: string;
	Token?: string;
	LeadToken?: string;
	Status?: ENUM_SPPA_STATUS;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;

	PolicyholderBirthDate?: Date;
	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: string;
	PolicyholderCountryName?: string;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: string;
	PolicyholderProvinceName?: string;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: string;
	PolicyholderCityName?: string;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: string;
	PolicyholderPostalName?: string;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;
	PolicyholderPotentialAmount?: number;

	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: number;
	PremiumAmountAfterDicount?: number;
	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: number;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommissionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	PaymentAccountID?: number;

	/* PAYMENT - END */

	/* STATUS - START */

	ApprovedOn?: Date;
	ApprovedBy?: number;
	RejectedOn?: Date;
	RejectedBy?: number;
	RejectedNote?: string;
	Tenor?: number;
	Margin?: number;
	BatchNumber?: string;
	BatchCode?: string;
	PolicyRegistrationNumber?: string;
	BatchCareNumber?: string;
	MicroInsuranceNumber?: string;
	BooleanContributionNote?: boolean;
	BooleanComissionNote?: boolean;
	BooleanPolicyEmpty?: boolean;
	BooleanCoverageOverview?: boolean;
	ApprovedStartDate?: Date;
	ApprovedEndDate?: Date;
	CreatedStartDate?: Date;
	CreatedEndDate?: Date;

	/* STATUS - END */

	/* NOT MAPPED - START */

	listModelProductUmrahStorage?: Array<ProductUmrahModel>;
	modelProductUmrah?: ProductUmrahModel;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelProductUmrah = new ProductUmrahModel();
	}

	//#endregion


	//#region SETTER

	setModelProductUmrah(): void
	{
		const stringModelProductUmrah: string = JSON.stringify(this.modelProductUmrah);
		this.modelProductUmrah = new ProductUmrahModel();
		this.modelProductUmrah.setModelFromString(stringModelProductUmrah);
	}

	//#endregion


	//#region FUNCTION

	premiumTotalAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined && this.PremiumAmount !== undefined)
		{
			this.PremiumTotalAmount = this.AdministrationAmount + this.StampAmount + this.PremiumAmount;
		}
		else
		{

		}
	}

	getName(): string
	{
		if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined || this.PolicyholderFirstName === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = this.PolicyholderFirstName;

			if (this.PolicyholderMiddleName == null || this.PolicyholderMiddleName === undefined || this.PolicyholderMiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.PolicyholderMiddleName;
			}

			if (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined || this.PolicyholderLastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.PolicyholderLastName;
			}

			return stringName;
		}
	}

	//#endregion


	//#region VALIDATE

	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Download dokumen.");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.Token == null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Documen token kosong!";
		}
		else if (!regularExpression.test(this.Token))
		{
			modelResponse.MessageContent = "Token is in incorrect format!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateProductUmrahForReport(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product Umrah");

		if (this.PolicyStartDate == null || this.PolicyStartDate === undefined || this.PolicyEndDate == null || this.PolicyEndDate === undefined)
		{
			modelResponse.MessageContent = "Product general is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			if (this.PolicyStartDate > this.PolicyEndDate)
			{
				modelResponse.MessageContent = "Periode Awal tidak boleh lebih besar daripada Periode Akhir! Silahkan isi!";
			}
			else if (this.PolicyEndDate === this.PolicyStartDate)
			{
				modelResponse.MessageContent = "Periode Awal dan Periode Akhir tidak boleh sama! Silahkan isi!";
			}
			else if (this.PolicyEndDate.calculateDayDifference(this.PolicyStartDate) > 31)
			{
				modelResponse.MessageContent = "Periode tidak boleh lebih dari 31 hari";
			}
			else
			{
				modelResponse.MessageContent = "Product general is filled correctly";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validatePolicyholderEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	validateProductForInsertList(arrayFileDocument: Array<FileDocumentModel>): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General");

		if (arrayFileDocument === null || arrayFileDocument === undefined || arrayFileDocument.length <= 0)
		{
			modelResponse.MessageContent = "Bukti pembayaran tidak boleh kosong, silahkan unggah bukti pembayaran!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateProductForReport(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General");

		if (this.PolicyStartDate == null || this.PolicyStartDate === undefined || this.PolicyEndDate == null || this.PolicyEndDate === undefined)
		{
			modelResponse.MessageContent = "Product general is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			if (this.PolicyStartDate > this.PolicyEndDate)
			{
				modelResponse.MessageContent = "Periode Awal tidak boleh lebih besar daripada Periode Akhir! Silahkan isi!";
			}
			else if (this.PolicyEndDate === this.PolicyStartDate)
			{
				modelResponse.MessageContent = "Periode Awal dan Periode Akhir tidak boleh sama! Silahkan isi!";
			}
			else if (this.PolicyEndDate.calculateDayDifference(this.PolicyStartDate) > 30)
			{
				modelResponse.MessageContent = "Periode tidak boleh lebih dari 30 hari";
			}
			else
			{
				modelResponse.MessageContent = "Product general is filled correctly";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateProductForHajjAndUmrah(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General");

		if (this.CreatedStartDate == null || this.CreatedStartDate === undefined || this.CreatedEndDate == null || this.CreatedEndDate === undefined)
		{
			modelResponse.MessageContent = "Product general is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			if (this.CreatedStartDate > this.CreatedEndDate)
			{
				modelResponse.MessageContent = "Periode Awal tidak boleh lebih besar daripada Periode Akhir! Silahkan isi!";
			}
			else if (this.CreatedEndDate === this.CreatedStartDate)
			{
				modelResponse.MessageContent = "Periode Awal dan Periode Akhir tidak boleh sama! Silahkan isi!";
			}
			else if (this.CreatedEndDate.calculateDayDifference(this.CreatedStartDate) > 31)
			{
				modelResponse.MessageContent = "Periode tidak boleh lebih dari 31 hari";
			}
			else
			{
				modelResponse.MessageContent = "Product general is filled correctly";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateUmrahAExcelForListenUpload(arrayExcel: [][])
	{
		const modelResponse: ResponseModel = new ResponseModel();
		let modelValidate: ValidateModel = new ValidateModel();
		const arrayValidateModel: Array<ValidateModel> = [];
		const arrayProductGeneralModelRequest: Array<ProductGeneralModel> = [];
		let modelProductGeneralTemp: ProductGeneralModel;
		const regExAplhaNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);
		const regExAplhabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETWITHSPACE);
		modelResponse.setForValidation("Unggahan lampiran Permohonan Umrah");

		let stringNumberBirthDate: string | number;
		let stringNumberDepartureDate: string | number;
		let stringNumberArrivalDate: string | number;
		let validateArrivalDate: string | number;
		let booleanValidation: boolean;

		if(arrayExcel.length === 1)
		{
			modelResponse.MessageContent = "Format Excel kosong, mohon diisi sesuai format";
		}

		// file deepcode ignore UnusedIterator: <please specify a reason of ignoring this>
		for (let numberIndexRow = 1; numberIndexRow < arrayExcel.length; numberIndexRow++)
		{
			booleanValidation = true;
			modelProductGeneralTemp = new ProductGeneralModel();
			modelProductGeneralTemp.modelProductUmrah = new ProductUmrahModel();

			/* INSURED NAME - START */

			modelProductGeneralTemp.modelProductUmrah.InsuredName = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_INSUREDNAME];

			if (modelProductGeneralTemp.modelProductUmrah.InsuredName == null || modelProductGeneralTemp.modelProductUmrah.InsuredName === undefined || modelProductGeneralTemp.modelProductUmrah.InsuredName === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 1;
				modelValidate.MessageContent = "Kolom 'Nama Peserta' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else
			{
			}

			/* INSURED NAME - END */

			/* BIRTH PLACE - START */

			modelProductGeneralTemp.modelProductUmrah.InsuredBirthPlace = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_BIRTHPLACE];

			if (modelProductGeneralTemp.modelProductUmrah.InsuredBirthPlace == null || modelProductGeneralTemp.modelProductUmrah.InsuredBirthPlace === undefined || modelProductGeneralTemp.modelProductUmrah.InsuredBirthPlace === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 2;
				modelValidate.MessageContent = "Kolom 'Tempat Lahir Peserta' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else
			{
			}

			/* BIRTH PLACE - END */

			/* BIRTH DATE - START */

			stringNumberBirthDate = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_BIRTHDATE];

			if (stringNumberBirthDate != null || stringNumberBirthDate !== undefined || stringNumberBirthDate !== "")
			{
				if (typeof(stringNumberBirthDate) === "string")
				{
					modelProductGeneralTemp.modelProductUmrah.InsuredBirthDate = this.convertStringDateToDate(stringNumberBirthDate as string);
				}
				else if (typeof(stringNumberBirthDate) === "number")
				{
					modelProductGeneralTemp.modelProductUmrah.InsuredBirthDate = this.convertExcelDateToDate(stringNumberBirthDate as number);
				}
				else
				{
					booleanValidation = false;
				}

				if (booleanValidation)
				{
					let dateDay: Date = new Date();
					dateDay = dateDay.generateDateToday();

					if (modelProductGeneralTemp.modelProductUmrah.InsuredBirthDate == null || modelProductGeneralTemp.modelProductUmrah.InsuredBirthDate === undefined)
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 3;
						modelValidate.MessageContent = "Kolom 'Tanggal Lahir Peserta' tidak boleh kosong!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.modelProductUmrah.InsuredBirthDate.toString() === "Invalid Date")
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 3;
						modelValidate.MessageContent = "Kolom 'Tanggal Lahir Peserta' memiliki format yang salah!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.modelProductUmrah.InsuredBirthDate > dateDay)
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 3;
						modelValidate.MessageContent = "Kolom 'Tanggal Lahir' tidak boleh lewat dari pada hari ini.";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else
					{

					}
				}
				else
				{
				}
			}
			else
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 3;
				modelValidate.MessageContent = "Kolom 'Tanggal Lahir Peserta' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}

			/* BIRTH DATE - END */

			/* GENDER - START */

			let stringGender: string;
			stringGender = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_GENDER] as string;

			if (stringGender == null || stringGender === undefined || stringGender === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 4;
				modelValidate.MessageContent = "Kolom 'Gender Peserta' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringGender !== "P" && stringGender !== "L")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 4;
				modelValidate.MessageContent = "Kolom 'Gender Peserta' tidak boleh selain P atau L";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringGender === "P")
			{
				modelProductGeneralTemp.modelProductUmrah.InsuredGender = ENUM_GENDER_TYPE.Female;
			}
			else if (stringGender === "L")
			{
				modelProductGeneralTemp.modelProductUmrah.InsuredGender = ENUM_GENDER_TYPE.Male;
			}
			else
			{
			}

			/* GENDER - END */

			/* OCCUPATION - START */

			modelProductGeneralTemp.modelProductUmrah.InsuredOccupation = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_OCCUPATION] as string;

			if (modelProductGeneralTemp.modelProductUmrah.InsuredOccupation == null || modelProductGeneralTemp.modelProductUmrah.InsuredOccupation === undefined || modelProductGeneralTemp.modelProductUmrah.InsuredOccupation === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 5;
				modelValidate.MessageContent = "Kolom 'Pekerjaan Peserta' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (modelProductGeneralTemp.modelProductUmrah.InsuredOccupation !== null || modelProductGeneralTemp.modelProductUmrah.InsuredOccupation !== undefined)
			{
				if (!regExAplhabet.test(modelProductGeneralTemp.modelProductUmrah.InsuredOccupation))
				{
					modelValidate = new ValidateModel();
					modelValidate.RowMatrix = numberIndexRow + 1;
					modelValidate.ColumnMatrix = 5;
					modelValidate.MessageContent = "'Pekerjaan Peserta' hanya boleh berupa huruf!";
					arrayValidateModel.push(modelValidate);
				}
			}

			/* OCCUPATION - END */

			/* MARTIAL STATUS - START */

			let stringMartialStatus: string;
			stringMartialStatus = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_MARTIALSTATUS] as string;

			if (stringMartialStatus == null || stringMartialStatus === undefined || stringMartialStatus === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 6;
				modelValidate.MessageContent = "Kolom 'Status Perkawinan' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringMartialStatus === "Belum Kawin")
			{
				modelProductGeneralTemp.modelProductUmrah.InsuredMaritalStatus = ENUM_MARITAL_STATUS.Single ;
			}
			else if (stringMartialStatus === "Kawin")
			{
				modelProductGeneralTemp.modelProductUmrah.InsuredMaritalStatus = ENUM_MARITAL_STATUS.Married;
			}
			else if (stringMartialStatus === "Cerai Hidup")
			{
				modelProductGeneralTemp.modelProductUmrah.InsuredMaritalStatus = ENUM_MARITAL_STATUS.Divorce;
			}
			else if (stringMartialStatus === "Cerai Mati")
			{
				modelProductGeneralTemp.modelProductUmrah.InsuredMaritalStatus = ENUM_MARITAL_STATUS.Widow;
			}
			else if (stringMartialStatus !== null || stringMartialStatus === undefined || stringMartialStatus === "")
			{
				if (stringMartialStatus !== "Kawin" && stringMartialStatus !== "Belum Kawin" && stringMartialStatus !== "Cerai Hidup" && stringMartialStatus !== "Cerai Mati")
				{
					modelValidate = new ValidateModel();
					modelValidate.RowMatrix = numberIndexRow + 1;
					modelValidate.ColumnMatrix = 6;
					modelValidate.MessageContent = "Kolom 'Status Perkawinan' tidak boleh selain Belum Kawin, Kawin, Cerai Hidup, Cerai Mati, silahkan isi sesuai field!";
					arrayValidateModel.push(modelValidate);
				}
			}

			/* MARTIAL STATUS - END */

			/* PASSPORT NUMBER - START */

			modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_PASSPORT] as string;

			if (modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber == null || modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber === undefined || modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 7;
				modelValidate.MessageContent = "Kolom 'Nomor Paspor' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber !== null || modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber !== undefined)
			{
				for (let numberIndexColumn = numberIndexRow+1; numberIndexColumn < arrayExcel.length; numberIndexColumn++)
				{
					if (arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_PASSPORT] === arrayExcel[numberIndexColumn][NumberConstant.NUMBER_EXCEL_COLUMN_PASSPORT])
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 7;
						modelValidate.MessageContent = "Ditemukan nomor Paspor sama, " + arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_PASSPORT] + ", pada baris " + (numberIndexRow + 1) + " dan " + (numberIndexColumn + 1);
						arrayValidateModel.push(modelValidate);
					}
					else
					{
					}
				}

				if (!regExAplhaNumeric.test(modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber))
				{
					modelValidate = new ValidateModel();
					modelValidate.RowMatrix = numberIndexRow + 1;
					modelValidate.ColumnMatrix = 8;
					modelValidate.MessageContent = "Nomor paspor hanya boleh berupa huruf dan angka!";
					arrayValidateModel.push(modelValidate);
				}
				else if (modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber.toString().length < NumberConstant.NUMBER_VALIDATE_INSUREDPASSPORTNUMBERMIN || modelProductGeneralTemp.modelProductUmrah.InsuredPassportNumber.toString().length > NumberConstant.NUMBER_VALIDATE_INSUREDPASSPORTNUMBERMAX)
				{
					modelValidate = new ValidateModel();
					modelValidate.RowMatrix = numberIndexRow + 1;
					modelValidate.ColumnMatrix = 8;
					modelValidate.MessageContent = "Panjang digit 'nomor paspor' dibawah 6 atau diatas 12 digit!";
					arrayValidateModel.push(modelValidate);
				}
				else
				{
				}
			}
			else
			{
			}

			/* PASSPORT NUMBER - END */

			/* FLIGTH NUMBER - START */

			modelProductGeneralTemp.modelProductUmrah.InsuredFlightNumber = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_FLIGHTNUMBER] as string;

			if (modelProductGeneralTemp.modelProductUmrah.InsuredFlightNumber != null || modelProductGeneralTemp.modelProductUmrah.InsuredFlightNumber !== undefined)
			{
				if (!regExAplhaNumeric.test(modelProductGeneralTemp.modelProductUmrah.InsuredFlightNumber))
				{
					modelValidate = new ValidateModel();
					modelValidate.RowMatrix = numberIndexRow + 1;
					modelValidate.ColumnMatrix = 9;
					modelValidate.MessageContent = "Nomor paspor hanya boleh berupa huruf dan angka!";
					arrayValidateModel.push(modelValidate);
				}
				else if (modelProductGeneralTemp.modelProductUmrah.InsuredFlightNumber.toString().length < NumberConstant.NUMBER_VALIDATE_INSUREDFLIGHTNUMBERMIN || modelProductGeneralTemp.modelProductUmrah.InsuredFlightNumber.toString().length > NumberConstant.NUMBER_VALIDATE_INSUREDFLIGHTNUMBERMAX)
				{
					modelValidate = new ValidateModel();
					modelValidate.RowMatrix = numberIndexRow + 1;
					modelValidate.ColumnMatrix = 9;
					modelValidate.MessageContent = "Panjang digit 'nomor penerbangan' dibawah 3 atau diatas 10 digit!";
					arrayValidateModel.push(modelValidate);
				}
				else
				{
				}
			}
			else
			{
			}

			/* FLIGHT NUMBER - END */

			/* TYPE PRODUCT - START */

			let stringProductType: string;
			stringProductType = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_PERIODTYPE] as string;
			stringProductType = stringProductType.toString();

			if (stringProductType == null || stringProductType === undefined || stringProductType === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 10;
				modelValidate.MessageContent = "Kolom 'Periode' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringProductType !== "1" && stringProductType !== "2" && stringProductType !== "3")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 10;
				modelValidate.MessageContent = "Kolom 'Periode' tidak boleh selain 1,2 atau 3";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringProductType === "1")
			{
				modelProductGeneralTemp.modelProductUmrah.PeriodType = ENUM_UMRAH_PERIOD.FirstPeriod ;
			}
			else if (stringProductType === "2")
			{
				modelProductGeneralTemp.modelProductUmrah.PeriodType = ENUM_UMRAH_PERIOD.SecondPeriod;
			}
			else if (stringProductType === "3")
			{
				modelProductGeneralTemp.modelProductUmrah.PeriodType = ENUM_UMRAH_PERIOD.ThirdPeriod;
			}
			else
			{
			}

			/* TYPE PRODUCT - END */

			/* INSURED DEPATURE DATE - START */

			stringNumberDepartureDate = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_DEPARTUREDATE];

			if (stringNumberDepartureDate != null || stringNumberDepartureDate !== undefined || stringNumberDepartureDate !== "")
			{
				if (typeof(stringNumberDepartureDate) === "string")
				{
					modelProductGeneralTemp.modelProductUmrah.InsuredDepartureDate = this.convertStringDateToDate(stringNumberDepartureDate as string);
				}
				else if (typeof(stringNumberDepartureDate) === "number")
				{
					modelProductGeneralTemp.modelProductUmrah.InsuredDepartureDate = this.convertExcelDateToDate(stringNumberDepartureDate as number);
				}
				else
				{
					booleanValidation = false;
				}

				if (booleanValidation)
				{
					let dateDay: Date = new Date();
					dateDay = dateDay.generateDateToday();

					if (modelProductGeneralTemp.modelProductUmrah.InsuredDepartureDate == null || modelProductGeneralTemp.modelProductUmrah.InsuredDepartureDate === undefined)
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 11;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Keberangkatan' tidak boleh kosong!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.modelProductUmrah.InsuredDepartureDate.toString() === "Invalid Date")
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 11;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Keberangkatan' memiliki format yang salah!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if ((modelProductGeneralTemp.modelProductUmrah.InsuredDepartureDate < dateDay) || (modelProductGeneralTemp.modelProductUmrah.InsuredDepartureDate.getFullYear() < new Date().getFullYear()))
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 11;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Keberangkatan' tidak boleh kurang dari hari ini.";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else
					{

					}
				}
				else
				{
				}
			}
			else
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 8;
				modelValidate.MessageContent = "Kolom 'Tanggal Mulai Keberangkatan' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}

			/* INSURED DEPATURE DATE - END */

			/* INSURED ARRIVAL DATE - START */

			stringNumberArrivalDate = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_ARRIVALDATE];

			if (stringNumberArrivalDate != null || stringNumberArrivalDate !== undefined || stringNumberArrivalDate !== "")
			{
				if (typeof(stringNumberArrivalDate) === "string")
				{
					modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate = this.convertStringDateToDate(stringNumberArrivalDate as string);
				}
				else if (typeof(stringNumberArrivalDate) === "number")
				{
					modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate = this.convertExcelDateToDate(stringNumberArrivalDate as number);
				}
				else
				{
					booleanValidation = false;
				}

				if (booleanValidation)
				{
					let dateDay: Date = new Date();
					dateDay = dateDay.generateDateToday();

					if (modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate == null || modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate === undefined)
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 9;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Kepulangan' tidak boleh kosong!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate.toString() === "Invalid Date")
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 9;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Kepulangan' memiliki format yang salah!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate.getFullYear() < new Date().getFullYear())
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 9;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Kepulangan' memiliki tahun lebih dari tahun ini! Gunakan HANYA tahun ini atau tahun yang sudah berlalu.";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate < dateDay)
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 9;
						modelValidate.MessageContent = "Kolom 'Tanggal Keberangkatan' tidak boleh melebihi dari kolom 'Tanggal Kepulangan'.";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate !== null || modelProductGeneralTemp.modelProductUmrah.InsuredArrivalDate !== undefined)
					{
						for (let numberIndexColumn = numberIndexRow+1; numberIndexColumn < arrayExcel.length; numberIndexColumn++)
						{
							if (arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_ARRIVALDATE] !== arrayExcel[numberIndexColumn][NumberConstant.NUMBER_EXCEL_COLUMN_ARRIVALDATE])
							{
								modelValidate = new ValidateModel();
								modelValidate.RowMatrix = numberIndexRow + 1;
								modelValidate.ColumnMatrix = 9;
								validateArrivalDate = arrayExcel[numberIndexColumn][NumberConstant.NUMBER_EXCEL_COLUMN_ARRIVALDATE];
								modelValidate.MessageContent = "Ditemukan tanggal Kepulangan yang berbeda, " + this.convertExcelDateToDate(validateArrivalDate).toDateString() + ", pada baris " + (numberIndexRow + 1) + " dan " + (numberIndexColumn + 1);
								arrayValidateModel.push(modelValidate);
							}
							else
							{
							}
						}
					}
					else
					{

					}
				}
				else
				{
				}
			}
			else
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 9;
				modelValidate.MessageContent = "Kolom 'Tanggal Mulai Kepulangan' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}

			/* INSURED ARRIVAL DATE - END */

			/* TYPE COVERAGE - START */

			let stringCoverageType: string;
			stringCoverageType = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_COVERAGETYPE];

			if (stringCoverageType == null || stringCoverageType === undefined || stringCoverageType === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 10;
				modelValidate.MessageContent = "Kolom 'Periode' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringCoverageType !== "AHU-01" && stringCoverageType !== "AHU-03")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 10;
				modelValidate.MessageContent = "Kolom 'Coverage' tidak boleh selain AHU-01,AHU-03";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringCoverageType === "AHU-01")
			{
				modelProductGeneralTemp.modelProductUmrah.CoverageCode = StringConstant.STRING_FIELD_COVERAGE_STANDART;
			}
			else if (stringCoverageType === "AHU-03")
			{
				modelProductGeneralTemp.modelProductUmrah.CoverageCode = StringConstant.STRING_FIELD_COVERAGE_PLUSPASTANDART;
			}
			else
			{
			}

			/* TYPE PRODUCT - END */


			if (arrayValidateModel.length > 0)
			{
				modelResponse.MessageContent = "Terjadi kesalahan pada Permohonan BRINS Safar yang diunggah! Silahkan lihat tabel untuk daftar kesalahan.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else
			{
				arrayProductGeneralModelRequest.push(modelProductGeneralTemp);

				modelResponse.State = ENUM_RESPONSE_STATE.Success;
				modelResponse.MessageContent = "Form is filled correctly.";
			}
		}

		return [ modelResponse, arrayValidateModel, arrayProductGeneralModelRequest ];
	}

	//#endregion


	//#region GETTER

	getStatus(): string
	{
		if (this.Status == null || this.Status === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (this.Status === ENUM_SPPA_STATUS.RejectedSurvey)
		{
			return "Ditolak";
		}
		else if (this.Status === ENUM_SPPA_STATUS.Approved)
		{
			return "Disetujui";
		}
		else if (this.Status === ENUM_SPPA_STATUS.OnProgress)
		{
			return "Dalam Proses 	";
		}
		else
		{
			return ENUM_SPPA_STATUS[this.Status];
		}
	}

	//#endregion


	//#region FUNCTION

	convertStringDateToDate(stringValue: any): Date
	{
		const arrayDateValue: [] = stringValue.split("/");

		return new Date(arrayDateValue[NumberConstant.NUMBER_EXCEL_COLUMN_BIRTHDATE+2], arrayDateValue[NumberConstant.NUMBER_EXCEL_COLUMN_BIRTHDATE+1]-1, arrayDateValue[NumberConstant.NUMBER_EXCEL_COLUMN_BIRTHDATE]);
	}

	convertExcelDateToDate(serial)
	{
		return new Date(-2209075200000 + (serial - (serial < 61 ? 0 : 1)) * 86400000);
	}

	//#endregion
}

//#endregion