<div id="divBodySignIn">
	<header id="headerSignIn">
		<h1>Safar</h1>

		<span id="spanSubTitle">Bri Insurance Syariah</span>
	</header>

	<form [ngClass]="{ 'FormSignInShow' : !_booleanFormSignInEmail, 'FormSignInHide' : _booleanFormSignInEmail }">
		<div class="DivForm">
			<h3>Masuk</h3>
			<fieldset>
				<label for="emailEmail">Email</label>
				<div class="DivInputHorizontalContainer">
					<input type="email" id="emailEmail" name="modelUserEmail" [(ngModel)]="_modelUser.Email" placeholder="masukkan email" autofocus>
				</div>
			</fieldset>
		</div>
		<input type="button" value="Masuk" (click)="callSelectTravelSyariahByEmail(false)">
		<div class="DivEmailNotRegistered">
			<p class="PEmailNotRegistered">Email tidak terdaftar ?
				<a (click)="selectByEmailInfo()" class="AContactUs">Hubungi kami</a>
			</p>
			<p class="PVersion"> Version {{ _stringVersion }} </p>
		</div>
	</form>

	<form [ngClass]="{ 'FormSignInHide' : !_booleanFormSignInEmail, 'FormSignInShow' : _booleanFormSignInEmail }">
		<div class="DivForm">
			<h3>Verifikasi</h3>
			<fieldset>
				<label for="passwordEmailToken1">Email Token</label>
				<div class="DivInputHorizontalContainer">
					<input type="number" id="passwordEmailToken1" class="InputToken" name="stringEmailToken1" [(ngModel)]="_arrayNumberEmailToken[0]" (keyup)="listenInputToken($event, 0)" (click)="clickInputToken(0);" maxlength="1" max="9" required>
					<input type="number" class="InputToken" name="stringEmailToken2" [(ngModel)]="_arrayNumberEmailToken[1]" (keyup)="listenInputToken($event, 1)" (click)="clickInputToken(1);" maxlength="1" max="9" required>
					<input type="number" class="InputToken" name="stringEmailToken3" [(ngModel)]="_arrayNumberEmailToken[2]" (keyup)="listenInputToken($event, 2)" (click)="clickInputToken(2);" maxlength="1" max="9" required>
					<input type="number" class="InputToken" name="stringEmailToken4" [(ngModel)]="_arrayNumberEmailToken[3]" (keyup)="listenInputToken($event, 3)" (click)="clickInputToken(3);" maxlength="1" max="9" required>
					<input type="number" class="InputToken" name="stringEmailToken5" [(ngModel)]="_arrayNumberEmailToken[4]" (keyup)="listenInputToken($event, 4)" (click)="clickInputToken(4);" maxlength="1" max="9" required>
					<input type="number" class="InputToken" name="stringEmailToken6" [(ngModel)]="_arrayNumberEmailToken[5]" (keyup)="listenInputToken($event, 5)" (click)="clickInputToken(5);" maxlength="1" max="9" required>
				</div>
			</fieldset>
		</div>
			<input value="Kirim ulang" type="button" (click)="callSelectTravelSyariahByEmail(false)">
	</form>
</div>

<div class="DiviframeSignIn">
	<a href="https://www.ssltrust.com.au/security-report?domain=travelsyariah.syariah.web.brinesia.app" rel="nofollow" target="new"><img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a>

	<iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0" id="iframeSignIn"></iframe>
</div>
