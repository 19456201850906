<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Tentang</h2>
		<p>Temukan tentang Website BRINS Safar dan bagaimana untuk mendapatkan bantuan.</p>
    </header>

	<div id="divMainDashboard">
		<div class="DivContainer" style="text-align: justify;">
			<form>
				<div class="DivForm">
					<h3>BRINS Safar</h3>

					<img id="imageLogoAbout" src="../../assets/logos/sakina/logo_launcher_safar.svg" alt="BRI Insurance - Logo Header">

					<label >Versi</label>
					<label>{{ _enumStringConstant.STRING_VALUE_APPLICATION_VERSION }} | {{ _environmentBuild }}</label>

					<label>Deskripsi</label>
					<label>Asuransi BRINS Haji & Umroh merupakan produk komprehensif yang memberikan perlindungan atas kejadian yang tidak terduga yang bisa terjadi selama ibadah Haji atau Umroh. Asuransi BRINS Haji & Umroh memberikan manfaat santunan kematian dan biaya perawatan medis yang disebabkan karena sakit atau cidera akibat kecelakaan serta manfaat bagi Peserta yang mengalami kerusakan atau kehilangan bagasi selama menjalani proses ibadah Haji & Umroh.</label>

					<span>Perlindungan yang diberikan</span>
					<label>Asuransi BRINS Umroh memiliki 2 jenis paket Asuransi, yaitu :</label>
					<div class="DivGeneralProduct">
						<span>1. PAKET UMRAH STANDAR (Kode : AHU-01)</span>
						<ol>
							<li>Perawatan Medis</li>
								<label class="LabelProduct">Asuransi BRINS Haji dan Umroh akan memberikan manfaat biaya perawatan medis disebabkan karena cidera akibat kecelakaan. Perawatan medis ini akan meliputi:</label>
								<ul>
									<li>
										Perawatan Medis Di Luar Negeri
										<label class="LabelSubProduct">Manfaat penggantian biaya rawat inap apabila Peserta cidera akibat kecelakaan di luar negeri selama perjalanan. Total manfaat perawatan medis baik diluar maupun perawatan lanjutan di dalam negeri MAKSIMUM Rp 100.000.000,- (seratus juta Rupiah). Biaya perawatan atas penyakit bawaan MAKSIMUM Rp 10.000.000,- (sepuluh juta Rupiah) dan tidak berlaku untuk perawatan lanjutan di dalam negeri.
										</label>
									</li>
									<li>
										Perawatan Medis Lanjutan Di Indonesia
										<label class="LabelSubProduct">Manfaat penggantian biaya rawat inap apabila Peserta melakukan perawatan lanjutan di Indonesia maksimal 30 (tiga puluh) Hari Kalender sejak kedatangan, akibat dari cidera yang terjadi selama perjalanan di luar negeri.
										</label>
									</li>
								</ul>
							<li>Meninggal Dunia Akibat Kecelakaan</li>
							<label class="LabelProduct">Memberikan manfaat berupa pemberian santunan setinggi tingginya Rp50.000.000,- (lima puluh juta Rupiah) apabila Peserta meninggal dunia pada saat sebelum perjalanan Umrah atau saat Perjalanan di luar negeri. Peserta mengalami luka badan yang mengakibatkan kematian dalam waktu 180 (seratus delapan puluh) Hari Kalender sejak terjadinya kecelakaan. Tubuh Peserta tidak dapat ditemukan dalam waktu 1 (satu) tahun sejak pendaratan darurat, terdampar, tenggelam atau hancurnya alat angkut yang Peserta gunakan atau Peserta telah dinyatakan hilang oleh Institusi yang Berwenang setelah terjadinya kejadian tersebut. Jika suatu saat setelah pembayaran Maslahat Takaful (Klaim), Peserta ditemukan dalam keadaan masih hidup, maka Peserta akan mengembalikan jumlah santunan yang telah dibayarkan kepada Pihak Kedua.</label>
							<li>Cacat Tetap Akibat Kecelakaan
								<label class="LabelSubProduct">Berikut tabel Pesentase penggantian cacat tetap akibat kecelakaan :</label>
							</li>
							<div class="DivTableProductContainer">
								<table>
									<thead>
										<tr>
											<th>NO</th>
											<th>URAIAN</th>
											<th>PERSENTASE</th>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td>1</td>
											<td>Hilang atau tidak berfungsinya kedua belah mata, kedua lengan, kedua tungkai kaki, satu mata dan satu lengan, satu mata dan satu tungkai kaki, atau satu tungkai kaki dan satu lengan</td>
											<td>100%</td>
										</tr>
										<tr>
											<td>2</td>
											<td>Lengan kanan mulai dari sendi bahu, lengan kiri mulai dari sendi bahu, lengan kanan mulai dari atasnya sendi siku, sati kaki mulai dari lutut sampai pangkal paha</td>
											<td>60%</td>
										</tr>
										<tr>
											<td>3</td>
											<td>Sebelah mata atau pendengaran pada kedua belah telinga</td>
											<td>50%</td>
										</tr>
										<tr>
											<td>4</td>
											<td>Lengan kiri mulai dari atas sendi siku atau tangan kanan mulai dari atas pergelangan tangan</td>
											<td>40%</td>
										</tr>
										<tr>
											<td>5</td>
											<td>Tangan kiri mulai dari atas pergelangan tangan</td>
											<td>30%</td>
										</tr>
										<tr>
											<td>6</td>
											<td>Satu kaki mulai dari mata kaki sampai lutut, atau pendegaran pada sebelah telinga</td>
											<td>25%</td>
										</tr>
										<tr>
											<td>7</td>
											<td>Ibu jari tangan kanan; ibu jari tangan kiri; jari telunjuk tangan kanan; jari telunjuk tangan kiri; jari kelingking tangan kanan; jari kelingking tangan kiri; jari tengah atau manis tangan kanan; jari tengan atau manis tangan kiri; satu ibu jari kaki; satu jari kaki lainnya; atau sebelah daun telinga secara keseluruhan</td>
											<td>5%</td>
										</tr>
									</tbody>
								</table>
							</div>

							<li>Santunan Wafat Karena Sakit / Sebab Lain</li>
								<ul>
									<li>Pemberian manfaat apabila Peserta meninggal dunia akibat selain dari hal-hal yang disebutkan pada bagian Kecelakaan, baik sebelum perjalanan Umroh dan saat perjalanan di luar negeri.</li>
									<li>Nilai manfaat atas santunan meninggal dunia sebesar Rp. 10.000.000,- (sepuluh juta Rupiah)</li>
									<li>Manfaat asuransi terhitung sejak 30 (tiga puluh) Hari Kalender sebelum tanggal keberangkatan <b>terhitung sejak tanggal terbit polis</b> hingga tanggal kepulangan.</li>
								</ul>
							<li>Gagal Berangkat</li>
							<label class="LabelProduct">Memberikan manfaat berupa penggantian biaya yang belum digunakan dan tidak dapat dibayarkan kembali maksimum Rp. 20.000.000,- (dua puluh juta Rupiah) apabila peserta terpaksa harus membatalkan perjalanan sebelum tanggal keberangkatan akibat dari satu atau lebih kejadian berikut ini:</label>
								<ul>
									<li>Peserta atau anggota keluarga dekat Peserta mengalami penyakit serius, kecelakaan serius, atau kematian, yang terjadi dalam waktu 30 (tiga puluh) Hari Kalender sebelum tanggal keberangkatan <b>terhitung sejak tanggal terbit polis.</b></li>
									<li>Terjadi pembatalan atas jasa layanan pesawat untuk perjalanan Peserta, yang disebabkan oleh adanya pemogokan, kerusuhan, pergerakan massa atau bencana alam.</li>
									<li>Adanya kewajiban bagi Peserta untuk menjalani karantina, menjadi juri atau saksi dalam pengadilan, yang diinformasikan dalam waktu 30 (tiga puluh) Hari Kalender sebelum tanggal keberangkatan <b>terhitung sejak tanggal terbit polis.</b></li>
									<li>Apabila secara medis tubuh Peserta tidak dapat menerima vaksinasi tertentu yang menjadi syarat masuk ke suatu negara tujuan.</li>
									<li>Tempat tinggal Peserta di Indonesia mengalami kerusakan besar karena kebakaran, banjir atau bencana alam sejenis yang terjadi dalam 7 (tujuh) Hari Kalender sebelum tanggal keberangkatan dan kehadiran peserta diperlukan di lokasi tempat tinggal tersebut pada saat tanggal keberangkatan.</li>
								</ul>
							<label class="LabelProduct">Manfaat yang diberikan adalah penggantian atas biaya-biaya yang telah Peserta bayarkan berupa: uang deposit/uang muka atau biaya pelunasan. Jika Peserta memiliki hak untuk mendapatkan penggantian atas sebagian atau seluruh biaya dari <b>Pihak</b> lain selain <b>Perjanjian</b> ini, manfaat yang akan diberikan adalah sisa biaya yang belum terganti setelah penggantian dari sumber lain tersebut, hingga batas yang telah ditentukan.</label>
							<li>Kerusakan dan Kehilangan Bagasi</li>
							<label class="LabelProduct">Memberikan manfaat apabila bagasi tercatat Peserta mengalami kerusakan atau hilang selama penerbangan. Bagasi dianggap hilang berdasarkan pernyataan maskapai penerbangan. Besarnya manfaat penggantian akan dihitung berdasar ketentuan sebagai berikut:</label>
								<ul>
									<li>Kerusakan Bagasi
										<label class="LabelSubProduct">Dihitung berdasarkan biaya perbaikan atas kerusakan maksimum sebesar nilai bagasi dan setinggi-tingginya sebesar Rp. 5.000.000,- (lima juta Rupiah)</label>
									</li>
									<li>Kehilangan Bagasi
										<label class="LabelSubProduct">Dihitung berdasarkan berat bagasi yang hilang per kilogram, yaitu sebesar Rp. 500.000,- (lima ratus ribu Rupiah)/kg hingga maksimal sebesar Rp. 5.000.000,- (lima juta Rupiah)</label>
									</li>
								</ul>
							<label class="LabelProduct">Yang dimaksud bagasi tercatat adalah barang-barang yang Peserta bawa dan disimpan terpisah dari Peserta dalam satu moda transportasi yang sama dan teregistrasi oleh maskapai penerbangan. Barang bagasi tidak termasuk air Zam Zam yang menjadi jatah pembagian dari Penyelenggara Perjalanan Ibadah Umrah (PPIU).</label>
							<label class="LabelProduct">Pengajuan penggantian atas Kehilangan Bagasi dikenakan masa tunggu selama 7 (tujuh) Hari Kalender sejak jamaah tiba di bandara (Indonesia maupun Arab Saudi).</label>
							<label class="LabelProduct">Atas setiap kehilangan yang diberikan penggantian, apabila dalam waktu 30 (tiga puluh) Hari Kalender bagasi ditemukan, maka biaya penggantian wajib dikembalikan kepada <b>Pihak Kedua</b>.</label>
							<li>Pemulangan Medis Darurat dan Pemulangan Jenazah</li>
							<ul>
								<li>Pemulangan Medis Darurat
									<label class="LabelSubProduct">Penggantian biaya pemindahan atau biaya sarana transportasi yang dibutuhkan untuk mengantar Peserta ke Indonesia untuk kelanjutan perawatan setelah Peserta mengalami rawat inap di luar Indonesia. Besarnya manfaat setinggi-tingginya Rp. 50.000.000,- (lima puluh juta Rupiah).</label>
								</li>
								<li>Pemulangan Jenazah
									<label class="LabelSubProduct">Berupa penggantian biaya terkait pemulangan Jenazah Peserta kembali ke Indonesia setinggi-tingginya Rp50.000.000,- (lima puluh juta Rupiah).</label>
								</li>
							</ul>
						</ol>

						<div class="DivTableProductContainer">
							<table>
								<thead>
									<tr>
										<th>Kode</th>
										<th>Periode Umroh</th>
										<th>Standar</th>
									</tr>
								</thead>
				
								<tbody>
									<tr>
										<td>1</td>
										<td>9-15 Hari</td>
										<td>Rp 50.000</td>
									</tr>
									<tr>
										<td>2</td>
										<td>> 15-20 Hari</td>
										<td>Rp 75.000</td>
									</tr>
									<tr>
										<td>3</td>
										<td>> 20-30 Hari</td>
										<td>Rp 150.000</td>
									</tr>
								</tbody>
							</table>
						</div>

						<p>Note :</p>
						<label>Untuk periode umroh dibawah 9 hari, dapat memilih kode 1 (9-15 hari) pada saat mengisi formulir / unggah dokumen, namun periode keberangkatan dan periode kepulangannya tetap diisi sesuai dengan periode perjalanan ibadah umroh peserta.</label>
					</div>

					<div class="DivGeneralProduct">
						<span>2. PAKET UMRAH PLUS PERSONAL ACCIDENT (Kode : AHU-03)</span>
						<label class="LabelProduct">Memberikan manfaat yang terdapat pada Paket Umroh standar serta <b>penambahan</b> manfaat <b>Asuransi Personal Accident</b> yang berlaku <b>selama 7 hari sejak peserta tiba di Indonesia, sebagai berikut :</b></label>
						<ol>
								<li>Santunan cacat tetap akibat kecelakaan : Max. Rp. 10.000.000,- (Max. 7 hari setelah tiba di Indonesia)
								</li>
								<li>Santunan meninggal dunia akibat kecelakaan : Max. Rp. 10.000.000,- (Max. 7 hari setelah tiba di Indonesia)
								</li>
								<li>Santunan biaya pengobatan akibat kecelakaan : Max. Rp. 1.000.000,- (Max. 7 hari setelah tiba di Indonesia)</li>
						</ol>

						<label>Besar Kontribusi :</label>

						<div class="DivTableProductContainer">
							<table>
								<thead>
									<tr>
										<th>Kode</th>
										<th>Periode Umroh</th>
										<th>Plus PA Standar</th>
									</tr>
								</thead>
				
								<tbody>
									<tr>
										<td>1</td>
										<td>9-15 Hari</td>
										<td>Rp 75.000</td>
									</tr>
									<tr>
										<td>2</td>
										<td>> 15-20 Hari</td>
										<td>Rp 100.000</td>
									</tr>
									<tr>
										<td>3</td>
										<td>> 20-30 Hari</td>
										<td>Rp 175.000</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="DivGeneralProduct">
						<span>Pengecualian</span>
						<label class="LabelProduct">Asuransi BRINS Haji dan Umroh ini tidak memberikan manfaat atas hal-hal yang dikecualikan sebagaimana dijelaskan dibawah ini:</label>
						<ol>
							<li>Manfaat Sakit tidak berlaku atas sebab-sebab/kondisi-kondisi yang dikecualikan sebagai berikut:</li>
								<ul>
									<li>Menderita burut (hernia), sengatan matahari, terserang atau terjangkit gangguan-gangguan atau virus atau kuman  penyakit dalam arti yang seluas-luasnya dan mengakibatkan antara lain timbulnya demam (hayfever), typhus, paratyphus, disentri, peracunan dalam makanan (botulism), malaria, sampar (leptospirosis), filaria dan penyakit tidur karena gigitan atau sengatan serangga kedalam tubuh, asthma.</li>
									<li>Penyakit menular atau infeksi seperti sakit tidur, malaria, paludism, demam kuning, semua jenis penyakit tidak sadarkan diri, syncope, stroke, ayan (epilepsy), dan disebabkan oleh hilangnya kesadaran sebagai akibat dari suatu kecelakaan.</li>
									<li>Mengalami bertambah parahnya akibat-akibat kecelakaan karena mengidap penyakit gula, peredaran darah yang kurang baik, pembesaran pembuluh darah, butanya satu mata jika mata yang lain tertimpa kecelakaan.</li>
									<li>Biaya-biaya yang dikeluarkan untuk mencegah atau mengurangi kerugian kecuali jika telah disetujui Pengelola.</li>
									<li>Biaya-biaya yang seharusnya dapat dikompensasi oleh Polis lain yang lebih khusus termasuk program Asuransi Sosial atau Asuransi Wajib yang diatur oleh Undang-Undang.</li>
									<li>Akibat dari radiasi, reaksi atau kontaminasi nuklir termasuk senjata nuklir atau radioaktif.</li>
									<li>Akibat dari bahan, zat, senyawa biologis dan/atau kimiawi yang digunakan baik secara langsung maupun tidak langsung dengan tujuan untuk membahayakan atau membunuh nyawa manusia dan/atau menimbulkan ketakutan publik.</li>
									<li>Biaya medis apapun yang berhubungan dengan penyakit epidemik atau kehamilan.</li>
									<li>Penyakit pandemi.</li>
								</ul>
							<li>Manfaat Kecelakaan tidak berlaku atas sebab-sebab/kondisi-kondisi yang dikecualikan sebagai berikut:</li>
								<ul>
									<li>Melakukan penerbangan atau perjalanan udara selain sebagai penumpang dalam penerbangan terdaftar yang dioperasikan oleh perusahaan penerbangan atau perusahaan carter.</li>
									<li>Olahraga berbahaya/ekstrim misalnya bertinju, bergulat dan semua jenis olah raga beladiri, rugby, hockey, olah raga di atas es atau salju, mendaki gunung atau gunung es dan semua jenis olah raga kontak fisik, bungy jumping dan sejenisnya, memasuki gua-gua atau lubang-lubang yang dalam, berburu binatang, atau jika Peserta berlayar seorang diri, atau berlatih untuk atau turut serta dalam perlombaan kecepatan atau ketangkasan mobil atau sepeda motor, olah raga udara dan olah raga air.</li>
									<li>Dengan sengaja melakukan atau turut serta dalam tindak kejahatan, atau melanggar Peraturan dan Perundang-undangan yang berlaku.</li>
									<li>Cidera yang disebabkan karena keikutsertaan dalam pemogokan.</li>
									<li>Cidera karena akibat dari operasi pembedahan atau pengobatan medis yang tidak disebabkan oleh kecelakaan yang ditanggung Polis ini.</li>
									<li>Berbagai kecelakaan yang dianggap sebagai kecelakaan kerja, yang risiko tersebut melekat pada jenis pekerjaan yang Peserta lakukan.</li>
									<li>Kecelakaan yang terjadi sebelum berlakunya asuransi syariah ini, terlepas dari fakta bahwa akibat tersebut berlanjut sampai pada masa berlakunya Polis.</li>
									<li>Kecelakaan sebagai akibat dari kecerobohan Peserta yang membahayakan jiwa atau raga, kecuali memang diperlukan dalam usaha menyelamatkan diri Peserta atau orang lain.</li>
									<li>Kecelakaan yang timbul sebagai akibat dari tindakan Peserta yang memaksakan diri untuk melakukan suatu aktivitas dimana Peserta sedang menderita sakit atau ketidaknormalan fisik.</li>
								</ul>
							<li>Manfaat Meninggal Dunia tidak berlaku atas sebab-sebab kondisi-kondisi yang dikecualikan sebagai berikut:</li>
								<ul>
									<li>Maslahat Takaful (Klaim) yang berhubungan baik secara langsung maupun tidak langsung dengan infeksi Human Immunodeficiency Virus (HIV), Acquired Immune Deficiency Syndrome (AIDS) atau penyakit menular seksual lainnya.</li>
									<li>Apabila Peserta dengan sengaja melakukan bunuh diri, mencoba untuk melakukan bunuh diri, atau dengan sengaja melukai dirinya sendiri, di bawah pengaruh alkohol atau obat-obatan terlarang, atau dengan sengaja melakukan pengrusakan.</li>
									<li>Apabila Peserta mengikuti perlombaan adu kecepatan, mendaki gunung, panjat tebing atau turut serta dalam aktifitas olahraga profesional, menyelam dimana Peserta tidak memiliki sertifikat menyelam dan/atau menyelam tanpa pengawasan dari instruktur selam bersertifikat.</li>
									<li>Apabila Peserta meninggal dunia diakibatkan karena penyakit pandemi (pengecualian ini tidak berlaku apabila Peserta melakukan penutupan Asuransi BRINS Haji & Umroh dengan paket plus covid-19)</li>
								</ul>
							<li>Manfaat Gagal Berangkat tidak berlaku atas sebab-sebab/kondisi-kondisi yang dikecualikan sebagai berikut:</li>
								<ul>
									<li>Peserta tidak memiliki Nomor Porsi Umrah.</li>
									<li>Peserta telah mengikuti program cicilan Umrah dengan skema ponzi, sistem berjenjang, atau skema investasi yang melanggar perundang-undangan.</li>
									<li>Ketidakjujuran, tindak kriminal, atau tindak pidana Peserta atau siapapun yang berhubungan atau berkaitan dengan Peserta.</li>
									<li>Peristiwa perang, baik melalui pernyataan perang resmi ataupun tidak, atau aksi pemberontakan, revolusi, atau pengambilalihan kekuasaan oleh militer.</li>
									<li>Campur tangan pemerintah dalam bentuk apapun, gagal mendapatkan visa atau dokumen kunjungan lain, pelarangan, pengaturan atau diberlakukannya peringatan Perjalanan (travel warning) dari yang berwenang.</li>
								</ul>
							<li>Manfaat Kerusakan dan Kehilangan Bagasi tidak berlaku atas sebab-sebab/kondisi-kondisi yang dikecualikan sebagai berikut:</li>
								<ul>
									<li>Uang tunai, surat setara uang tunai, cek, alat negosiasi atau surat berharga.</li>
									<li>Bagasi atau barang pribadi yang tanpa pengawasan.</li>
									<li>Barang bawaaan dan bagasi akibat koper/ tas atau kemasan yang rapuh.</li>
									<li>Goresan/tergores pada barang bawaan dan bagasi.</li>
									<li>Keausan atau penurunan nilai dari barang atau kerusakan yang diakibatkan oleh serangga atau hewan pengerat, jamur, lumut, karat atau korosi.</li>
									<li>Kerusakan mekanik atau elektrik dari peralatan mekanik atau elektronik yang dibawa selama perjalanan, atau ongkos perbaikan kerusakan.</li>
									<li>Penundaan, penahanan, perampasan atau penyitaan oleh petugas Imigrasi atau Pihak berwenang lain.</li>
									<li>Kerugian, kehilangan atau kerusakan atas barang apapun yang diangkut sebagai muatan/ kargo baik yang tercatat dalam Bill of Lading/ Airways Bill maupun tidak, kecuali disepakati ditanggung didalam Polis ini</li>
								</ul>
							<li>Manfaat Pemulangan Medis Darurat tidak berlaku atas sebab-sebab/kondisi-kondisi yang dikecualikan sebagai berikut:</li>
								<ul>
									<li>Biaya-biaya yang timbul dari pelayanan yang disediakan <b>Pihak</b> lain di mana Peserta tidak bertanggung jawab untuk membayar, atau biaya-biaya yang sudah termasuk dalam biaya perjalanan</li>
									<li>Biaya-biaya yang timbul yang tidak disetujui oleh <b>Pihak Kedua</b></li>
									<li>Segala tindakan yang dilakukan atau diminta oleh seseorang yang bukan Praktisi Medis</li>
									<li>Segala biaya yang timbul dalam hal tindakan yang bisa ditunda sampai Peserta dapat kembali ke Indonesia.</li>
								</ul>
							<li>Manfaat Pemulangan Jenazah tidak berlaku atas sebab-sebab/kondisi-kondisi yang dikecualikan sebagai berikut:</li>
								<ul>
									<li>Meninggal dunia karena narkotika dan obat-obat terlarang</li>
									<li>Meninggal dunia karena bunuh diri</li>
									<li>Biaya-biaya yang timbul dari pelayanan yang disediakan Pihak lain di mana Peserta tidak bertanggung jawab untuk membayar, atau biaya-biaya yang sudah termasuk dalam biaya perjalanan</li>
									<li>Biaya-biaya yang timbul yang tidak disetujui oleh Pengelola</li>
									<li>Biaya prosedural pemakaman di Indonesia</li>
									<li>Biaya pemakaman dan upacara pemakaman/keagamaan</li>
								</ul>
						</ol>
					</div>

					<label>Bantuan</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_FIELD_EMAIL_SYARIAH_BRINS }}" (click)="selectByEmailTSI()">

					<h3>Kontak</h3>
					<p>PT. BRI Asuransi Indonesia</p>
					<label>Jl. Mampang Prapatan Raya No.18 South Jakarta 12790, Indonesia</label>

					<label>Telepon</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA + _enumStringConstant.STRING_VALUE_TELEPHONE_BRINS_PRIMARY }}" (click)="selectByTelephonePrimary()">
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA + _enumStringConstant.STRING_VALUE_TELEPHONE_BRINS_SECONDARY }}" (click)="selectByTelephoneSecondary()">

					<label for>Email</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_FIELD_EMAIL_SYARIAH_BRINS }}" (click)="selectByEmailInfo()">

					<label for>Call Center</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_VALUE_TELEPHONE_CALLCENTER }}" (click)="selectByCallCenter()">
				</div>
			</form>
		</div>
	</div>
</div>
