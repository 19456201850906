<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Dasbor</h2>
		<p>Asuransi BRINS Safar</p>
    </header>

	<div id="divMainDashboard" class="DivContainerVertical">
        <div class="DivContainer">
			<form>
				<div class="DivForm">
					<h3>Saring berdasarkan</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="datePeriod">Tanggal Pembaruan</label>
								<mat-form-field appearance="fill">
									<mat-label>Estimasi Jarak Tanggal</mat-label>
									<mat-date-range-input [rangePicker]="picker">
										<input matStartDate placeholder="Tanggal awal" [value]="_modelDashboardBase.StartDate" (dateChange)="getStartDate($event)">
										<input matEndDate placeholder="Tanggal akhir" [value]="_modelDashboardBase.EndDate" (dateChange)="getEndDate($event)">
									</mat-date-range-input>
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-date-range-picker #picker></mat-date-range-picker>
								</mat-form-field>
							</div>
						</div>
					</fieldset>
				</div>
			</form>

			<div id="divFormStatus" class="DivForm">
				<h3>Status Permohonan Umrah</h3>
				<div class="DivFormVerticalContainer">
					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHajjAndUmrahSummary.modelColorStatusTypeProductUmrah"
						[results]="_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusTypeProductUmrah"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusTypeProductUmrah?.length ?? 0) < 10"
						[legendTitle]="'Status'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow">
					</ngx-charts-bar-vertical>

					<h3>Menunggu Disetujui</h3>

					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHajjAndUmrahSummary.modelColorStatusOnProgressProductUmrah"
						[results]="_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusOnProgressProductUmrah"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusOnProgressProductUmrah?.length ?? 0) < 10"
						[legendTitle]="'Menunggu Di Setujui'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow">
					</ngx-charts-bar-vertical>

					<h3>Disetujui</h3>

					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHajjAndUmrahSummary.modelColorStatusApprovedProductUmrah"
						[results]="_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusApprovedProductUmrah"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusApprovedProductUmrah?.length ?? 0) < 10"
						[legendTitle]="'Di tolak'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow">
					</ngx-charts-bar-vertical>

					<h3>Ditolak</h3>

					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHajjAndUmrahSummary.modelColorStatusRejectedProductUmrah"
						[results]="_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusRejectedProductUmrah"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHajjAndUmrahSummary.listModelChartItemStatusRejectedProductUmrah?.length ?? 0) < 10"
						[legendTitle]="'Di Setujui'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow"
						[xAxisLabel]="'true'"
						[yAxisLabel]="'true'">
					</ngx-charts-bar-vertical>
				</div>
			</div>

			<div id="divFormBuildingPrice" class="DivForm">
				<h3>Total Kontribusi Umrah</h3>

				<div class="DivFormVerticalContainer">
					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHajjAndUmrahSummary.modelColorStatusPremiumAmountProductUmrah"
						[results]="_modelDashboardProductHajjAndUmrahSummary.listModelChartItemPremiumAmountProductUmrah"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHajjAndUmrahSummary.listModelChartItemPremiumAmountProductUmrah?.length ?? 0) < 10"
						[legendTitle]="'Nilai Premi'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow"
						[xAxisLabel]="'true'"
						[yAxisLabel]="'true'">
					</ngx-charts-bar-vertical>
				</div>
			</div>

			<div id="divFormBuildingPrice" class="DivForm">
				<h3>Total Polis Umrah</h3>

				<div class="DivFormVerticalContainer">
					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHajjAndUmrahSummary.modelColorStatusPolicyProductUmrah"
						[results]="_modelDashboardProductHajjAndUmrahSummary.listModelChartItemPolicyProductUmrah"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHajjAndUmrahSummary.listModelChartItemPolicyProductUmrahSyariah?.length ?? 0) > 10"
						[legendTitle]="'Total Policy'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow"
						[xAxisLabel]="'true'"
						[yAxisLabel]="'true'">
					</ngx-charts-bar-vertical>
				</div>
			</div>
		</div>
	</div>
</div>